import { configureStore } from "@reduxjs/toolkit";
import { adminApiSlice } from "../api/adminSlice";
import { analysisApiSlice } from "../api/analysisSlice";
import { announcementApiSlice } from "../api/announcementSlice";
import { authApiSlice } from "../api/authSlice";
import { batchApiSlice } from "../api/batchSlice";
import { chatAiApiSlice } from "../api/chatAiSlice";
import { examApiSlice } from "../api/examSlice";
import { facultyApiSlice } from "../api/facultySlice";
import { meetApiSlice } from "../api/meetManagerSlice";
import { ticketApiSlice } from "../api/raiseTicketSlice";
import { resultsApiSlice } from "../api/resultsSlice";
import { schoolApiSlice } from "../api/schoolSlice";
import { supervisorApiSlice } from "../api/supervisorSlice";
import { syllabusApiSlice } from "../api/syllabusSlice";
import { taskApiSlice } from "../api/taskSlice";
import { userApiSlice } from "../api/userSlice";
import { samlApiSlice } from "../api/samlSlice";
import { performanceApiSlice } from "../api/performanceSlice";
import announcementReducer from "../features/Announcement/announcementSlice";
import commentReducer from "../features/AnnouncementComment/commentSlice";
import authReducer from "../features/auth/authSlice";
import batchReducer from "../features/batch/batchSlice";
import examReducer from "../features/exam/examSlice";
import resultReducer from "../features/results/resultSlice";
import supervisorReducer from "../features/supervisor/supervisorSlice";

// redux store
export const store = configureStore({
  reducer: {
    [authApiSlice.reducerPath]: authApiSlice.reducer,
    [supervisorApiSlice.reducerPath]: supervisorApiSlice.reducer,
    [announcementApiSlice.reducerPath]: announcementApiSlice.reducer,
    [batchApiSlice.reducerPath]: batchApiSlice.reducer,
    [examApiSlice.reducerPath]: examApiSlice.reducer,
    [syllabusApiSlice.reducerPath]: syllabusApiSlice.reducer,
    [analysisApiSlice.reducerPath]: analysisApiSlice.reducer,
    [facultyApiSlice.reducerPath]: facultyApiSlice.reducer,
    [schoolApiSlice.reducerPath]: schoolApiSlice.reducer,
    [taskApiSlice.reducerPath]: taskApiSlice.reducer,
    [adminApiSlice.reducerPath]: adminApiSlice.reducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [ticketApiSlice.reducerPath]: ticketApiSlice.reducer,
    [meetApiSlice.reducerPath]: meetApiSlice.reducer,
    [resultsApiSlice.reducerPath]: resultsApiSlice.reducer,
    [chatAiApiSlice.reducerPath]: chatAiApiSlice.reducer,
    [samlApiSlice.reducerPath]: samlApiSlice.reducer,
    [performanceApiSlice.reducerPath]: performanceApiSlice.reducer,
    supervisor: supervisorReducer,
    auth: authReducer,
    exam: examReducer,
    batch: batchReducer,
    announcements: announcementReducer,
    comments: commentReducer,
    results: resultReducer
  },
  // prettier-ignore
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(
      authApiSlice.middleware,
      supervisorApiSlice.middleware,
      batchApiSlice.middleware,
      announcementApiSlice.middleware,
      examApiSlice.middleware,
      syllabusApiSlice.middleware,
      analysisApiSlice.middleware,
      facultyApiSlice.middleware,
      schoolApiSlice.middleware,
      taskApiSlice.middleware,
      adminApiSlice.middleware,
      userApiSlice.middleware,
      ticketApiSlice.middleware,
      meetApiSlice.middleware,
      resultsApiSlice.middleware,
      chatAiApiSlice.middleware,
      samlApiSlice.middleware,
      performanceApiSlice.middleware
    )
});
