import React, { useState, useEffect } from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import UserCurrentStandingStats from "./UserCurrentStandingStats";
import SubjectAnalysis from "./SubjectAnalysis";
import DetailedAnalysis from "./DetailedAnalysis";
import ScoreAnalysis from "./ScoreAnalysis";
import DifficultyAnalysis from "./DifficultyAnalysis";
import {
  useGetAllStudentAnalysisQuery,
  useGetStudentAnalysisOfAnExamWithRangeQuery
} from "../../../redux/api/analysisSlice";
import { useGetStudentResultByStudentIdQuery } from "../../../redux/api/resultsSlice";

const NewOverAllStats = ({ analysis }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const { analysisId } = useParams();
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);
  const { data: boardData, isSuccess: isBoardSuccess } = useGetStudentResultByStudentIdQuery({
    studentId: user._id,
    schoolId: user.school_id
  });

  const [totalExams, setTotalExams] = useState();
  const [examForDetailedAnalysis, setExamForDetailedAnalysis] = useState({});

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) {
          if (exam.exam_for !== "undefined") uniqueExams.add(exam.exam_for);
        }
      });
      let currentStudentExamAnalysis = analysisData.analysisArray.filter(exam => exam._id === analysisId);
      setExamForDetailedAnalysis(currentStudentExamAnalysis);
      setTotalExams(Array.from(uniqueExams));
    }
  }, [analysisData]);

  const { data: allStudentsRangeData, isSuccess: isAllStudentsRangeDataSuccess } =
    useGetStudentAnalysisOfAnExamWithRangeQuery(
      {
        school_id: user.school_id,
        exam_id: examForDetailedAnalysis[0]?.exam_id,
        student_id: user._id
      },
      { skip: !examForDetailedAnalysis[0] }
    );

  return (
    <Box display="flex" flexDirection={"column"} gap={2} width="100%">
      {examForDetailedAnalysis && isAllStudentsRangeDataSuccess && (
        <UserCurrentStandingStats
          allStudentsRangeData={allStudentsRangeData}
          studentAnalysisDetails={examForDetailedAnalysis}
          rank={allStudentsRangeData?.rank}
        />
      )}
      {isAllStudentsRangeDataSuccess && <ScoreAnalysis scoreAnalysisData={allStudentsRangeData?.examResponseDetails} />}
      {isAnalysisSuccess && isBoardSuccess && <SubjectAnalysis analysisData={analysisData} boardData={boardData} />}
      {examForDetailedAnalysis && isAllStudentsRangeDataSuccess && (
        <DifficultyAnalysis
          studentAnalysisDetails={[
            { subject: "Analysing and Evaluating", A: examForDetailedAnalysis[0].evaluating_and_analysing },
            { subject: "Creating", A: examForDetailedAnalysis[0].creating_question },
            { subject: "Remembering", A: examForDetailedAnalysis[0].remembering },
            { subject: "Understanding", A: examForDetailedAnalysis[0].understanding_questions },
            { subject: "Applying", A: examForDetailedAnalysis[0].applying_questions }
          ]}
        />
      )}
      {isAnalysisSuccess && <DetailedAnalysis analysisData={analysisData} />}
    </Box>
  );
};

export default NewOverAllStats;
