import { Box, Divider, MenuItem, Select, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Info } from "@phosphor-icons/react";
import { useGetEntireSyllabusQuery } from "../../../../redux/api/syllabusSlice";
import SectionAnalysis from "./Sections/SectionAnalysis";

const IndividualSubjectAnalysis = ({ analysis, syllabusAnalysis, alignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const [selectedItem, setSelectedItem] = useState("");

  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user?.school_id);
  // Update the selected section based on syllabusAnalysis and alignment
  useEffect(() => {
    if (syllabusAnalysis?.sectionAnalysis && Object.keys(syllabusAnalysis.sectionAnalysis).length && alignment) {
      setSelectedItem(Object.keys(syllabusAnalysis.sectionAnalysis)[0]);
    } else {
      setSelectedItem("");
    }
  }, [syllabusAnalysis, alignment]);

  const handleMenuItemChange = event => {
    setSelectedItem(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap="0.75rem" paddingBottom="3rem">
      {/* Displaying Selected Subject Name */}
      <Typography variant="h3" fontSize={32}>
        {alignment}
      </Typography>
      <Divider style={{ backgroundColor: "grey", width: "10%" }} />

      {/* Section-wise Analysis Header */}
      <Typography variant="h4">Section Wise Analysis</Typography>
      <Divider style={{ backgroundColor: "grey", width: "15%" }} />

      {/* Section Dropdown */}
      <Box display="flex" paddingY="1rem" width="100%">
        <Box width={"fit-content"} display="flex" alignItems="center" gap="1rem">
          <Typography variant="h5">Select a section: </Typography>
          <Select
            id="section"
            name="section"
            size="medium"
            displayEmpty
            value={selectedItem}
            onChange={handleMenuItemChange}
            sx={{ width: isNonMobile ? "fit-content" : "100%" }}
          >
            <MenuItem value="" disabled>
              {alignment}
            </MenuItem>
            {syllabusAnalysis?.sectionAnalysis && Object.keys(syllabusAnalysis.sectionAnalysis).length ? (
              Object.keys(syllabusAnalysis.sectionAnalysis).map(section => (
                <MenuItem key={section} value={section}>
                  {section}
                </MenuItem>
              ))
            ) : (
              <MenuItem value="Loading" disabled>
                Loading...
              </MenuItem>
            )}
          </Select>
        </Box>
      </Box>

      {/* Information and Analysis Section */}
      <Box display="flex" justifyContent="center" alignItems="center" gap="1rem">
        <Info size={24} weight="bold" color="blue" />
        <Typography variant="subtitle2">Select a section to view detailed analysis</Typography>
      </Box>

      {/* Section Analysis Component */}
      <SectionAnalysis syllabusAnalysis={syllabusAnalysis} subject={alignment} section={selectedItem} />
    </Box>
  );
};

export default IndividualSubjectAnalysis;
