import { useTheme } from "@emotion/react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useInitiateSamlLoginMutation } from "../../../redux/api/samlSlice";
import CButton from "../../../components/ui/Button";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const TestGenerator = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();
  const userId = useSelector(state => state.auth.user._id);
  const [initiateSamlLogin] = useInitiateSamlLoginMutation();
  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test Generator", link: "" }
  ];

  // Function to handle button click
  const handleButtonClick = async () => {
    if (!userId) {
      console.error("User ID is not available");
      return;
    }

    try {
      const response = await initiateSamlLogin(userId).unwrap();
      // Process the HTML response if needed
      // Example: Open it in a new window or parse it
      const newWindow = window.open();
      newWindow.document.open();
      newWindow.document.write(response);
      newWindow.document.close();
    } catch (error) {
      console.error("Error initiating SAML login:", error);
    }
  };

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "1rem 0", marginTop: "-1rem" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box display="flex" gap="0.75rem" justifyContent="space-between" flexDirection={isNonMobile ? "row" : "column"}>
        <Box>
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Test Generator
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            Generate tests by clicking the button below
          </Typography>
        </Box>
      </Box>

      {/* Button in the middle of the page */}
      <Box display="flex" justifyContent="center" alignItems="center" height="100%">
        <CButton buttonText="Generate Test" variant="primary" onClickFun={handleButtonClick} />
      </Box>
    </Box>
  );
};

export default TestGenerator;
