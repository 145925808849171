import React from "react";
import { Box } from "@mui/material";

const SuperInsights = () => {
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      bgcolor="#ffffff"
      border={"1px solid #E6EDFF"}
      borderRadius={"0.5rem"}
      p="0.5rem"
    >
      <Box color="#3267ff" display={"flex"} alignItems={"center"}>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3 12.5C7.5 12.5 12 8 12 3.5C12 8 16.5 12.5 21 12.5C16.5 12.5 12 17 12 21.5C12 17 7.5 12.5 3 12.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M2 20C2.83333 20 4.5 18.3333 4.5 17.5C4.5 18.3333 6.16667 20 7 20C6.16667 20 4.5 21.6667 4.5 22.5C4.5 21.6667 2.83333 20 2 20Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
          <path
            d="M16 5.5C17 5.5 19 3.5 19 2.5C19 3.5 21 5.5 22 5.5C21 5.5 19 7.5 19 8.5C19 7.5 17 5.5 16 5.5Z"
            stroke="#3267FF"
            strokeWidth="1.5"
            strokeLinejoin="round"
          />
        </svg>

        <h4 className="m-0 ml-2 font-semibold">Super Insights</h4>
      </Box>
      <p className="text-[#4F4E69] m-2">
        Hey! Your Physics score has dipped/gained by 'Y%' from past exams and gained/dipped 'Z%' compared to the class
        average over the last 'X' exams.
      </p>
    </Box>
  );
};

export default SuperInsights;
