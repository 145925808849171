import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, ToggleButton, ToggleButtonGroup, Typography, styled, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ChapterAnalysisGraph from "./GraphsComponent/ChapterAnalysisGraph";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem",
    color: "#fff"
  }
}));

const ChapterAnalysis = ({ chapterAnalysisData, sectionAnalysisData, syllabusData, subjectAlignment }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const { user } = useSelector(state => state.auth);
  const [chapterNames, setChapterNames] = useState([]);
  const [chapterDetails, setChapterDetails] = useState([]);
  const [sectionNames, setSectionNames] = useState([]);
  const [sectionAlignment, setSectionAlignment] = useState();
  const [sectionsToDisplay, setSectionsToDisplay] = useState([]);
  const [visibleSections, setVisibleSections] = useState([0, 1]); // Keep track of the visible section range

  const handleScrollLeft = () => {
    if (visibleSections[0] > 0) {
      setVisibleSections([visibleSections[0] - 1, visibleSections[1] - 1]);
    }
  };

  const handleScrollRight = () => {
    if (visibleSections[1] < sectionsToDisplay.length - 1) {
      setVisibleSections([visibleSections[0] + 1, visibleSections[1] + 1]);
    }
  };

  useEffect(() => {
    if (chapterAnalysisData) {
      setChapterNames(Object.keys(chapterAnalysisData));
      setChapterDetails(Object.values(chapterAnalysisData));
    }
    if (sectionAnalysisData) {
      setSectionNames(Object.keys(sectionAnalysisData));
    }
  }, [chapterAnalysisData, sectionAnalysisData]);

  useEffect(() => {
    if (syllabusData && subjectAlignment) {
      const sections = Object.keys(syllabusData[subjectAlignment] || {});
      const filteredSections = sections.filter(section => section in sectionAnalysisData);
      setSectionsToDisplay(filteredSections);

      // Set initial section alignment if there are sections to display
      if (filteredSections.length > 0) {
        setSectionAlignment(filteredSections[0]);
      }
    }
  }, [syllabusData, subjectAlignment, sectionAnalysisData]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setSectionAlignment(newAlignment);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem"
      borderRadius="0.5rem"
      justifyContent="center"
      alignItems="center"
    >
      <Box width="100%" display="flex" justifyContent="center" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          gap="0.75rem"
          width="100%"
          justifyContent="center" // Ensure the wrapper is centered
        >
          {sectionsToDisplay.length > 2 && (
            <IconButton onClick={handleScrollLeft} disabled={visibleSections[0] === 0}>
              <ArrowBackIos />
            </IconButton>
          )}
          <Box
            display="flex"
            overflow="hidden"
            sx={{
              width: isNonMobile ? "50%" : "80%",
              maxWidth: "600px",
              justifyContent: "center" // Center the ToggleButtonGroup horizontally
            }}
          >
            <ToggleButtonGroup
              sx={{
                bgcolor: "#E9E5E5",
                width: "100%",
                height: "10%",
                borderRadius: "0.5rem",
                display: "flex",
                justifyContent: "center" // Center the buttons within the group
              }}
              value={sectionAlignment}
              exclusive
              onChange={handleToggle}
            >
              {sectionsToDisplay.length ? (
                sectionsToDisplay.slice(visibleSections[0], visibleSections[1] + 1).map((section, sectionIndex) => (
                  <StyledToggleButton value={section} key={sectionIndex} sx={{ width: "100%" }}>
                    <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
                      {section}
                    </Typography>
                  </StyledToggleButton>
                ))
              ) : (
                <Typography justifySelf="center" width="100%" bgcolor="#fff" textAlign="center">
                  No Sections To Display
                </Typography>
              )}
            </ToggleButtonGroup>
          </Box>
          {sectionsToDisplay.length > 2 && (
            <IconButton onClick={handleScrollRight} disabled={visibleSections[1] === sectionsToDisplay.length - 1}>
              <ArrowForwardIos />
            </IconButton>
          )}
        </Box>
      </Box>

      <Box display="flex" alignSelf="start" flexDirection="column" width="100%">
        <Box>
          <Typography variant="h3" fontWeight="medium">
            Chapter Analysis
          </Typography>
        </Box>
        <Box display="flex" alignSelf="center" justifySelf="center" width="100%">
          <ChapterAnalysisGraph
            subjectAlignment={subjectAlignment}
            sectionAlignment={sectionAlignment}
            syllabusData={syllabusData}
            chapterAnalysisData={chapterAnalysisData}
            setSectionAlignment={setSectionAlignment}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ChapterAnalysis;
