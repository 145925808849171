import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

const ChapterAnalysisGraph = ({
  chapterAnalysisData = {}, // Default empty object
  syllabusData = {},
  subjectAlignment = "",
  sectionAlignment = "",
  mainColors = ["#8979FF", "#FF928A", "#3CC3DF", "#FFAE4C"]
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [chapterArray, setChapterArray] = useState([]);
  const [highestTotal, setHighestTotal] = useState(0);

  useEffect(() => {
    if (syllabusData && subjectAlignment && sectionAlignment) {
      const chapters = syllabusData?.[subjectAlignment]?.[sectionAlignment] || [];
      setChapterArray(chapters);
    } else {
      setChapterArray([]);
    }
  }, [sectionAlignment, syllabusData, subjectAlignment]);

  useEffect(() => {
    let maxTotal = 0;
    chapterArray.forEach(chapterName => {
      const chapterData = chapterAnalysisData[chapterName] || {};
      if (chapterData.total > maxTotal) {
        maxTotal = chapterData.total;
      }
    });
    setHighestTotal(maxTotal);
  }, [chapterArray, chapterAnalysisData]);

  const VerticalLine = ({ left }) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: `${left}`,
        width: "1px",
        borderLeft: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: `${top}`,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const GraphScaleDisplay = ({ highestTotal }) => {
    // Ensure highestTotal is at least 1 for proper scale division
    const maxTotal = Math.max(1, highestTotal); // Prevent division by zero

    // Calculate the step size by dividing the maxTotal by 5 and rounding up
    const step = Math.ceil(maxTotal / 5);

    // Determine the length of numberSection based on highestTotal
    const length = highestTotal < 5 ? highestTotal + 1 : 6; // +1 to include 0

    // Generate an array of values based on the length
    const numberSection = Array.from({ length }, (_, i) => i * step);

    return (
      <div className="h-full">
        {numberSection.map((item, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              bottom: `${(index / (numberSection.length - 1)) * 100}%`, // Distribute items from the bottom up
              transform: "translateY(50%) translateX(-100%)", // Center vertically relative to the position
              left: -4 // Offset to the left to display numbers outside the graph area
            }}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      style={{
        flex: 1,
        position: "absolute",
        backgroundColor: color,
        width: "100%",
        opacity: 0.3,
        height: "100%"
      }}
    />
  );

  const MainBar = ({ height, color, label }) => {
    const [animatedHeight, setAnimatedHeight] = useState(0);
    useEffect(() => {
      const timer = setTimeout(() => {
        setAnimatedHeight(height);
      }, 500);
      return () => clearTimeout(timer);
    }, [height]);

    return (
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          width: "100%",
          height: `${animatedHeight}%`,
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          color: "#fff",
          transition: "height 0.7s ease-in-out"
        }}
      >
        {label > 0 && <span>{label}</span>}
      </Box>
    );
  };

  return (
    <Box p="1rem 2rem" position="relative" width="100%">
      <Box
        position="relative"
        minHeight="40vh"
        width="100%"
        bgcolor="#fff"
        border="1px dashed #e3e3e3"
        borderBottom="1px solid #000"
      >
        {chapterArray.length > 0 &&
          chapterArray
            .filter(chapterName => chapterName in chapterAnalysisData)
            .map((chapterName, index) => (
              <VerticalLine key={index} left={`${(index + 1) * (100 / (chapterArray?.length ?? 100))}%`} />
            ))}

        <HorizontalLine top="20%" />
        <HorizontalLine top="40%" />
        <HorizontalLine top="60%" />
        <HorizontalLine top="80%" />

        <GraphScaleDisplay highestTotal={highestTotal} />

        <Box
          display="flex"
          justifyContent="space-evenly"
          bgcolor={"transparent"}
          position="absolute"
          zIndex={100}
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          {chapterArray.length > 0 &&
            chapterArray
              .filter(chapterName => chapterName in chapterAnalysisData)
              .map((chapterName, sectionIndex) => {
                const chapterData = chapterAnalysisData?.[chapterName] || {};
                const unanswered = chapterData.total - (chapterData.correct + chapterData.wrong);
                return (
                  <Box
                    key={sectionIndex}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="flex-end"
                    flex={1}
                    height="100%"
                    bgcolor="transparent"
                    width="100%"
                  >
                    <Box
                      display="flex"
                      width="70%"
                      height="100%"
                      flexDirection="row"
                      alignItems="flex-center"
                      position="relative"
                      gap={"0.5%"}
                      bottom={0}
                    >
                      <Box position="relative" width="25%">
                        <BackgroundBoxOfGraph color={mainColors[0]} />
                        <MainBar
                          height={(chapterData.total / (highestTotal || 1)) * 100}
                          color={mainColors[0]}
                          label={chapterData.total}
                        />
                      </Box>

                      <Box position="relative" width="25%">
                        <BackgroundBoxOfGraph color={mainColors[1]} />
                        <MainBar
                          height={(chapterData.correct / (highestTotal || 1)) * 100}
                          color={mainColors[1]}
                          label={chapterData.correct}
                        />
                      </Box>

                      <Box position="relative" width="25%">
                        <BackgroundBoxOfGraph color={mainColors[2]} />
                        <MainBar
                          height={(chapterData.wrong / (highestTotal || 1)) * 100}
                          color={mainColors[2]}
                          label={chapterData.wrong}
                        />
                      </Box>

                      <Box position="relative" width="25%">
                        <BackgroundBoxOfGraph color={mainColors[3]} />
                        <MainBar
                          height={(unanswered / (highestTotal || 1)) * 100}
                          color={mainColors[3]}
                          label={unanswered}
                        />
                      </Box>
                    </Box>

                    <Box position="absolute" bottom={"-20%"} sx={{ flexWrap: "wrap" }}>
                      {chapterName || ""}
                    </Box>
                  </Box>
                );
              })}
        </Box>
      </Box>

      <Box display="flex" flexDirection="row" justifyContent="center" gap="2rem" alignItems="center" mt="5rem">
        {mainColors.slice(0, 4).map((color, index) => (
          <Box key={index} display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
            <Box width="0.5rem" height="0.5rem" bgcolor={color} />
            <Box>{["Number Of Questions", "Answered Correctly", "Answered Incorrectly", "Unanswered"][index]}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ChapterAnalysisGraph;
