import { Box, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import NewOverAllStats from "../../../components/Student/ExamAnalysis/NewOverAllStats";
import { useGetAllStudentAnalysisQuery } from "../../../redux/api/analysisSlice";

const TestAnalysisStudent = () => {
  const { analysisId } = useParams();
  const [examId, setExamId] = useState("");
  const [selectedValue, setSelectedValue] = useState("Batch1");

  const handleChange = event => {
    setSelectedValue(event.target.value);
  };

  const theme = useTheme();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);
  const [analysis, setAnalysis] = useState({});

  useEffect(() => {
    const selectedAnalysis = analysisArray && analysisArray?.analysisArray?.find(item => item._id === analysisId);
    setExamId(selectedAnalysis?.exam_id);
    setAnalysis(selectedAnalysis || {});
  }, [analysisArray, analysisId]);

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" gap="0.75rem" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h3" fontWeight={600}>
            Exam Analysis of: {analysis.exam_name?.toUpperCase()}
          </Typography>
          <Typography variant="subtitle1" fontSize={16} color={theme.palette.primary.light}>
            Know about the progress report of your class
          </Typography>
        </Box>
      </Box>
      <NewOverAllStats analysis={analysis} />
    </Box>
  );
};

export default TestAnalysisStudent;
