import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SubjectAnalysisGraph from "./GraphsComponent/SubjectAnalysisGraph";

const SubjectAnalysis = ({ analysisData, boardData = {}, typeBoard = false }) => {
  let [totalExams, setTotalExams] = useState([]);
  const [examData, setExamData] = useState([]);

  const { analysisId } = useParams();

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0 && !typeBoard) {
      setExamData(
        analysisData.analysisArray.filter(item => {
          return item._id === analysisId;
        })
      );
    }

    if (boardData && boardData.result && typeBoard) {
      setExamData(boardData);
    }
  }, [analysisData, analysisId]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem 1rem"
      borderRadius="0.5rem"
      position="relative"
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <h2 className="m-0 text-black font-medium text-[24px]">Subject Wise Analysis</h2>
      </Box>
      {!typeBoard && (
        <Grid container columnSpacing={2}>
          <Grid item xs={12} md={8} xl={8}>
            {examData.length !== 0 && <SubjectAnalysisGraph examData={examData} />}
            {examData.length === 0 && (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                You have not attempted any tests yet.
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={4} xl={4}>
            <Box
              display="flex"
              flexDirection="column"
              gap="0.75rem"
              bgcolor="#fafafb"
              border="1px solid #E6EDFF"
              width="100%"
              height="50%"
              mt="1rem"
              p="1rem 1rem"
              borderRadius="0.5rem"
              position="relative"
            >
              <h3 className="m-0">How Subject Analysis Works?</h3>
              <p className="m-0 -mt-1 text-wrap text-start text-[#4F4E69]">
                Insights algorithms generate a detailed subject-wise analysis by averaging your performance across all
                tests and benchmarking it against the class average within your specific batch.
              </p>
            </Box>
          </Grid>
        </Grid>
      )}
      {typeBoard && (
        <Grid container columnSpacing={1}>
          <Grid item xs={12} md={12} xl={12}>
            {boardData.length !== 0 && <SubjectAnalysis examData={examData} />}
            {examData.length === 0 && (
              <Box height="100%" display="flex" justifyContent="center" alignItems="center">
                You have not attempted any tests yet.
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SubjectAnalysis;
