import React from "react";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import { useGetFacultiesQuery } from "../../../redux/api/supervisorSlice";
import { useGetBatchesQuery } from "../../../redux/api/batchSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const FacultyDetails = ({ facultyDetailState, setFacultyDetailState, batchDetailState, setBatchDetailState }) => {
  const { user } = useSelector(state => state.auth);
  const { data: faculties } = useGetFacultiesQuery(user.school_id);
  const { data: batches } = useGetBatchesQuery(user.school_id);

  const handleChange = event => {
    const {
      target: { value }
    } = event;

    // Update selected faculty names in the state
    setFacultyDetailState(prevState => ({
      ...prevState,
      facultyName: typeof value === "string" ? value.split(",") : value
    }));

    // Update selected faculty IDs in the state
    const selectedIds = value.map(name => faculties?.faculties.find(faculty => faculty.name === name)._id);
    setFacultyDetailState(prevState => ({
      ...prevState,
      selectedFacultyIds: selectedIds
    }));
  };

  const handleBatchChange = event => {
    const {
      target: { value }
    } = event;

    // Update selected faculty names in the state
    setBatchDetailState(prevState => ({
      ...prevState,
      batchName: typeof value === "string" ? value.split(",") : value
    }));

    // Update selected faculty IDs in the state
    const selectedIds = value.map(name => batches?.batches.find(batch => batch.batch_name === name)._id);
    setBatchDetailState(prevState => ({
      ...prevState,
      selectedBatchIds: selectedIds
    }));
  };

  return (
    <Box py="2rem" display="flex" flexDirection="column" gap="1rem">
      <Typography variant="h4" mb="0.2rem">
        Select Faculty
      </Typography>
      <Divider />
      <FormControl>
        <InputLabel id="demo-multiple-checkbox-label">Select here:</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={facultyDetailState.facultyName}
          onChange={handleChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={selected => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{
            bgcolor: "#F7F6F9",
            width: "100%"
          }}
        >
          {faculties &&
            faculties?.faculties.map(faculty => (
              <MenuItem sx={{ height: "35px" }} key={faculty.name} value={faculty.name}>
                <Checkbox checked={facultyDetailState.facultyName.indexOf(faculty.name) > -1} />
                <ListItemText primary={faculty.name} primaryTypographyProps={{ fontSize: "15px" }} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Divider />
      <Typography variant="h4" mb="0.2rem">
        Select Batch
      </Typography>
      <Divider />
      <FormControl>
        <InputLabel id="demo-multiple-checkbox-label">Select here:</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={batchDetailState.batchName}
          onChange={handleBatchChange}
          input={<OutlinedInput label="Tag" />}
          renderValue={selected => selected.join(", ")}
          MenuProps={MenuProps}
          sx={{
            bgcolor: "#F7F6F9",
            width: "100%"
          }}
        >
          {batches &&
            batches?.batches.map((batch, index) => (
              <MenuItem sx={{ height: "35px" }} key={batch.batch_name} value={batch.batch_name}>
                <Checkbox checked={batchDetailState.batchName.indexOf(batch.batch_name) > -1} />
                <ListItemText primary={batch.batch_name} primaryTypographyProps={{ fontSize: "15px" }} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FacultyDetails;
