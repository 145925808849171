import { Box, Button, Divider, Step, StepLabel, Stepper, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArrowRight, Check } from "@phosphor-icons/react";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAddExamToBatchAnalysisforExamsMutation } from "../../../redux/api/batchAnalysis";
import { useUpdateABatchforExamsMutation } from "../../../redux/api/batchSlice";
import {
  useCreateAExamMutation,
  useCreateQuestionsBulkMutation,
  useGetQuestionCountMutation,
  useUpdateAExamMutation
} from "../../../redux/api/examSlice";
import { setExamInEdit } from "../../../redux/features/exam/examSlice";
import { getId } from "../../../utils/uniqueIdGen";
import CButton from "../../ui/Button";
import ExamDetails from "./ExamDetails";
import FacultyDetails from "./FacultyDetails";
import OmrUpload from "./OmrUpload";
import QuestionSetter from "./QuestionSetter";

// import { useFetchStaffQuery } from "../../../redux/services/api";

const steps = ["Exam Details", "Faculty Details", "Subject Analysis", "Upload OMR"];

const ExamStepper = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const theme = useTheme();
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const startAnalysisRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Redux Store Variables
  const { user } = useSelector(state => state.auth);
  const { examInEdit } = useSelector(state => state.exam);

  const [createAExam] = useCreateAExamMutation();
  const [updateAExam] = useUpdateAExamMutation();
  const [updateBatchForExam] = useUpdateABatchforExamsMutation();
  const [addExamToBatchAnalysis] = useAddExamToBatchAnalysisforExamsMutation();
  const [createQuestionsBulk] = useCreateQuestionsBulkMutation();
  const [getQuestionCount] = useGetQuestionCountMutation();

  const { exam, isSuccess, isError, message } = useSelector(state => state.exam);

  const [examDetailState, setExamDetailState] = useState({
    examType: "",
    examFor: "",
    examName: "",
    examDate: "",
    totalQuestions: 0,
    exam_description: ""
  });

  // FacultyDetails.jsx Props

  const [facultyDetailState, setFacultyDetailState] = useState({
    selectedFacultyIds: [],
    facultyName: []
  });

  const [batchDetailState, setBatchDetailState] = useState({
    selectedBatchIds: [],
    batchName: []
  });

  // QuestionSetter.jsx Props
  const [rows, setRows] = useState([]);
  const [finalQuestions, setFinalQuestions] = useState([]);

  // Pathname
  const { pathname } = useLocation();

  const [dynamicExamDataObj, setDynamicExamDataObj] = useState({
    school_id: user.school_id,
    unique_code: 0,
    exam_name: examDetailState.examName,
    type_of_exam: examDetailState.examType,
    exam_for: examDetailState.examFor,
    exam_date: examDetailState.examDate,
    exam_description: examDetailState.exam_description,
    subjects: [],
    sections: [],
    chapters: [],
    faculties: facultyDetailState.selectedFacultyIds,
    questions: [],
    total_questions: examDetailState.totalQuestions,
    status_step: 0,
    status: "DRAFT",
    _id: 0
  });

  useEffect(() => {
    if (examInEdit) {
      setDynamicExamDataObj(examInEdit);
    }
  }, [examInEdit]);

  useEffect(() => {
    if (pathname === "/analyser") {
      setDynamicExamDataObj({
        school_id: user.school_id,
        unique_code: 0,
        exam_name: examDetailState.examName,
        type_of_exam: examDetailState.examType,
        exam_for: examDetailState.examFor,
        exam_date: examDetailState.examDate,
        exam_description: examDetailState.exam_description,
        subjects: [],
        sections: [],
        chapters: [],
        faculties: facultyDetailState.selectedFacultyIds,
        questions: [],
        total_questions: examDetailState.totalQuestions,
        status_step: 0,
        status: "DRAFT",
        _id: 0
      });
    }
  }, [pathname]);

  const handleApi = async step => {
    if (step === 0) {
      const examDataObj = {
        ...dynamicExamDataObj,
        exam_name: examDetailState.examName,
        type_of_exam: examDetailState.examType,
        exam_for: examDetailState.examFor,
        exam_date: examDetailState.examDate,
        exam_description: examDetailState.exam_description,
        total_questions: examDetailState.totalQuestions,
        unique_code: dynamicExamDataObj._id ? dynamicExamDataObj.unique_code : getId(),
        status_step: 1
      };
      if (dynamicExamDataObj._id) {
        const { _id, ...updatedExamDataObj } = examDataObj;
        try {
          const response = await updateAExam({ id: dynamicExamDataObj._id, examData: updatedExamDataObj });
          setDynamicExamDataObj(response.data.exam); // Save the response in the state variable
          return 1;
        } catch (error) {
          console.error("Error updating exam:", error);
          return 0;
        }
      } else {
        try {
          const response = await createAExam(examDataObj);
          dispatch(setExamInEdit(response.data.exam));
          return 1;
        } catch (error) {
          console.error("Error creating exam:", error);
          return 0;
        }
      }
    } else if (step === 1) {
      const examDataObj = {
        ...dynamicExamDataObj,
        faculties: facultyDetailState.selectedFacultyIds,
        batches: batchDetailState.selectedBatchIds,
        status_step: 2
      };
      const { _id, ...updatedExamDataObj } = examDataObj;
      if (examDataObj._id) {
        try {
          const response = await updateAExam({ id: examDataObj._id, examData: updatedExamDataObj });
          // await updateBatchForExam({ batchIds: batchDetailState.selectedBatchIds, examId: examDataObj._id });
          await addExamToBatchAnalysis({
            batchIds: batchDetailState.selectedBatchIds,
            examId: examDataObj._id
          });
          dispatch(setExamInEdit(response.data.exam));
          return 1;
        } catch (error) {
          console.error("Error updating exam:", error);
          return 0;
        }
      }
    } else if (step === 2) {
      const examDataObj = {
        ...dynamicExamDataObj,
        status_step: 3
      };

      try {
        const totalQue = examDetailState.totalQuestions;
        const responseQuestionCount = await getQuestionCount({
          exam_unique_code: examDataObj.unique_code,
          school_id: user.school_id
        });

        if (totalQue - responseQuestionCount.data.questionsCount > 0) {
          toast.error(
            `Total questions chosen and number of questions filled do not match. You are ${
              totalQue - responseQuestionCount.data.questionsCount
            } questions short`
          );
          return 0; // Ensure this returns 0 to stop further steps
        }

        const response = await updateAExam({ id: examDataObj._id, examData: examDataObj });
        setDynamicExamDataObj(response.data.exam);
        return 1;
      } catch (error) {
        toast.error("Error creating exam");
        console.error("Error creating exam:", error);
        return 0; // Ensure this returns 0 on error
      }
    }
  };

  const checkSubmission = async activeStep => {
    if (activeStep === 0) {
      const { examType, examFor, examName, examDate, totalQuestions, exam_description } = examDetailState;

      if (!examType || !examFor || !examName || !examDate || totalQuestions === 0 || !exam_description) {
        toast.error("Please fill all the required fields");
        return 0;
      } else {
        return handleApi(activeStep);
      }
    } else if (activeStep === 1) {
      const { selectedFacultyIds } = facultyDetailState;
      const { selectedBatchIds } = batchDetailState;

      if (selectedFacultyIds.length === 0 || selectedBatchIds.length === 0) {
        toast.error("Please fill all the required fields");
        return 0;
      } else {
        let flag = handleApi(activeStep);
        return flag;
      }
    } else if (activeStep === 2) {
      return handleApi(activeStep);
    } else if (activeStep === 3) {
      startAnalysisRef.current.click();
    }
  };

  // to handle step submissions and step progression
  const isStepSkipped = step => {
    return skipped.has(step);
  };

  // to handle step submissions and step progression
  const handleNext = async () => {
    const flag = await checkSubmission(activeStep);
    if (flag === 1) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep(prevActiveStep => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  // to handle step submissions and step progression
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const showStep = actStep => {
    switch (actStep) {
      case 0:
        return <ExamDetails examDetailState={examDetailState} setExamDetailState={setExamDetailState} />;
      case 1:
        return (
          <FacultyDetails
            facultyDetailState={facultyDetailState}
            setFacultyDetailState={setFacultyDetailState}
            batchDetailState={batchDetailState}
            setBatchDetailState={setBatchDetailState}
          />
        );
      case 2:
        return <QuestionSetter />;
      case 3:
        return <OmrUpload examId={dynamicExamDataObj._id} startAnalysisRef={startAnalysisRef} />;
      default:
        return <Box>Not found</Box>;
    }
  };

  return (
    <Box px={isNonMobile ? "2rem" : "0.75rem"} py="0.5rem">
      <Box
        display="flex"
        flexDirection="column"
        sx={{ width: "100%", height: "100%" }}
        gap="0.5rem"
        alignItems="center"
      >
        <Stepper activeStep={activeStep} sx={isNonMobile ? { width: "100%" } : { width: "100%", ml: "-1.5rem" }}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};

            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }

            return (
              <Step key={label} {...stepProps} sx={{ mb: "0.5rem" }}>
                <StepLabel {...labelProps} StepIconProps={{ style: { color: "#2970FF" } }}>
                  <Typography variant="h6">{label}</Typography>
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Divider />
        <Box width="100%">
          <Box py="0.5rem">{showStep(activeStep)}</Box>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {activeStep === steps.length - 1 ? (
              <CButton
                onClickFun={handleNext}
                variant="primary"
                buttonText={
                  <Box display="flex" gap="5px" alignItems="center">
                    Start Analysis
                    <Check weight="bold" size={18} />
                  </Box>
                }
              />
            ) : (
              <CButton
                onClickFun={handleNext}
                variant="primary"
                buttonText={
                  <Box display="flex" alignItems="center" gap="5px">
                    Next
                    <ArrowRight
                      color="#fff"
                      weight="fill"
                      size={18}
                      style={{ "&:hover": { color: theme.palette.secondary.main } }}
                    />
                  </Box>
                }
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExamStepper;
