import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import { Box, FormControl, IconButton, MenuItem, Select, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";

import { useGetStudentPerformanceInRangeByBatchQuery } from "../../../redux/api/performanceSlice";

const OverallAnalysis = ({ userData, analysisData, totalExams, batchNames }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [examType, setExamType] = useState("All Exams");
  const [batchType, setBatchType] = useState("All Batches");
  const [sortedByDateData, setSortedByDateData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [interval, setInterval] = useState(1);
  const [date, setDate] = useState({
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
    endDate: new Date()
  });

  const handleBatchTypeChange = event => {
    setBatchType(event.target.value);
  };

  const { data: batchesByStudent, isSuccess: isbatchesByStudentSuccess } = useGetBatchesByStudentQuery({
    schoolId: userData.school_id,
    studentId: userData._id
  });

  const {
    data: studentPerformanceInRangeByBatch,
    isSuccess: isstudentPerformanceInRangeByBatchSuccess,
    isLoading: isstudentPerformanceInRangeByBatchLoading
  } = useGetStudentPerformanceInRangeByBatchQuery(
    {
      school_id: userData?.school_id,
      student_id: userData?._id,
      batch_name: batchNames?.[batchType] ?? "All Batches",
      startDate: date.startDate,
      endDate: date.endDate
    },
    {
      skip: batchNames?.length === 0
    }
  );

  const handleExamTypeChange = event => {
    const selectedExamType = event.target.value;
    setExamType(selectedExamType);

    if (selectedExamType === "All Exams") {
      // Show all exams
      setGraphData(studentPerformanceInRangeByBatch);
    } else {
      // Filter by exam_for, check if exam_for exists
      const filteredData = studentPerformanceInRangeByBatch.filter(
        item => item.exam_for && item.exam_for === selectedExamType
      );
      // If there's no data for the selected exam, show the last available data
      if (filteredData.length > 0) {
        setGraphData(filteredData);
      } else {
        setGraphData([]); // Optionally show a "No Data" message in the graph
      }
    }
  };

  const handleIntervalChange = newInterval => {
    setInterval(newInterval);

    // Adjust start date based on the selected interval
    let newStartDate = new Date();
    newStartDate.setMonth(newStartDate.getMonth() - newInterval);
    setDate({ startDate: newStartDate, endDate: new Date() });
  };

  const handleBackwardClick = () => {
    // Move date range 1 month back
    const newEndDate = new Date(date.endDate);
    newEndDate.setMonth(newEndDate.getMonth() - interval);

    const newStartDate = new Date(date.startDate);
    newStartDate.setMonth(newStartDate.getMonth() - interval);

    setDate({ startDate: newStartDate, endDate: newEndDate });
  };

  const handleForwardClick = () => {
    // Move date range 1 month forward but not into the future
    const newEndDate = new Date(date.endDate);
    newEndDate.setMonth(newEndDate.getMonth() + interval);

    if (newEndDate > new Date()) return; // Prevent going beyond current date

    const newStartDate = new Date(date.startDate);
    newStartDate.setMonth(newStartDate.getMonth() + interval);

    setDate({ startDate: newStartDate, endDate: newEndDate });
  };

  useEffect(() => {
    setSortedByDateData(
      [...analysisData.analysisArray]
        .filter(item => examType === "All Exams" || item.exam_for === examType)
        .sort((a, b) => new Date(a.exam_date) - new Date(b.exam_date)) // Return sorted values
        .slice(-6)
    );
  }, [analysisData, examType]);

  useEffect(() => {
    // Set initial graphData when the batch performance data is fetched
    if (isstudentPerformanceInRangeByBatchSuccess) {
      setGraphData(studentPerformanceInRangeByBatch);
    }
  }, [isstudentPerformanceInRangeByBatchSuccess, studentPerformanceInRangeByBatch]);

  if (isstudentPerformanceInRangeByBatchLoading || !isstudentPerformanceInRangeByBatchSuccess) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="#fff"
      border="1px solid #E6EDFF"
      width="100%"
      height="100%"
      mt="1rem"
      p="1rem 1rem"
      borderRadius="0.5rem"
    >
      <h1 className="text-[20px] m-0 font-medium">Overall Analysis</h1>
      <Box display="flex" className="justify-between gap-3 md:flex-row flex-col">
        <Box
          display="grid"
          gap={2}
          sx={{
            gridTemplateColumns: {
              xs: "1fr", // 1 column on extra small screens
              sm: "1fr 1fr", // 2 columns on small screens
              md: "1fr 1fr 1fr" // 3 columns on medium and larger screens
            }
          }}
        >
          {/* Student Performance */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            bgcolor="#3267FF44"
            borderRadius="0.5rem"
            p="0.75rem"
            color="#000"
            textAlign="start"
          >
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length > 0 && (
                <>
                  <span style={{ fontWeight: 600, fontSize: "16px" }}>Your %</span>
                  <span className="flex items-center justify-center text-xl">
                    {graphData[0]?.student_percentage ?? 0}%
                    <span className="ml-1 text-xs text-[#6B6B6B]">
                      {graphData[0]?.student_percentage >= graphData[1]?.student_percentage ? (
                        <>
                          {Number(graphData[0]?.student_percentage) - Number(graphData[1]?.student_percentage)}%
                          <ArrowDownwardIcon sx={{ color: "#FF0000", height: "16px", width: "16px" }} />
                        </>
                      ) : (
                        <>
                          +{Number(graphData[1]?.student_percentage) - Number(graphData[0]?.student_percentage)}%
                          <ArrowUpwardIcon sx={{ color: "#3A974C", height: "16px", width: "16px" }} />
                        </>
                      )}
                      {!graphData[1] && <>0</>}
                    </span>
                  </span>
                </>
              )}
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length === 0 && <span>No Data Found</span>}
          </Box>

          {/* Class Average */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            bgcolor="#89BBAC"
            borderRadius="0.5rem"
            p="0.75rem"
            color="#000"
          >
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length > 0 && (
                <>
                  <span style={{ fontWeight: 600, fontSize: "16px" }}>Class Average %</span>
                  <span className="flex items-center justify-center text-xl">
                    {graphData[0]?.class_average ?? 0}%
                    <span className="ml-1 text-xs text-[#6B6B6B]">
                      {graphData[0]?.class_average >= graphData[1]?.class_average ? (
                        <>
                          +{Number(graphData[0]?.class_average) - Number(graphData[1]?.class_average)}%
                          <ArrowDownwardIcon sx={{ color: "#FF0000", height: "16px", width: "16px" }} />
                        </>
                      ) : (
                        <>
                          {Number(graphData[1]?.class_average) - Number(graphData[0]?.class_average)}%
                          <ArrowUpwardIcon sx={{ color: "#3A974C", height: "16px", width: "16px" }} />
                        </>
                      )}
                      {!graphData[1] && <>0</>}
                    </span>
                  </span>
                </>
              )}
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length === 0 && <span>No Data Found</span>}
          </Box>

          {/* Class Highest Scorer */}
          <Box
            display="flex"
            flexDirection="column"
            alignItems="start"
            justifyContent="center"
            bgcolor="#FF3B3044"
            borderRadius="0.5rem"
            p="0.75rem"
            color="#000"
          >
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length > 0 && (
                <>
                  <span style={{ fontWeight: 600, fontSize: "16px" }}>Highest Scorer %</span>
                  <span className="flex items-center justify-center text-xl">
                    {graphData[0]?.class_highest ?? 0}%
                    <span className="ml-1 text-xs text-[#6B6B6B]">
                      {graphData[0]?.class_highest >= graphData[1]?.class_highest ? (
                        <>
                          +{Number(graphData[0]?.class_highest) - Number(graphData[1]?.class_highest)}%
                          <ArrowUpwardIcon sx={{ color: "#FF0000", height: "16px", width: "16px" }} />
                        </>
                      ) : (
                        <>
                          {Number(graphData[1]?.class_highest) - Number(graphData[0]?.class_highest)}%
                          <ArrowUpwardIcon sx={{ color: "#3A974C", height: "16px", width: "16px" }} />
                        </>
                      )}
                      {!graphData[1] && <>0</>}
                    </span>
                  </span>
                </>
              )}
            {!isstudentPerformanceInRangeByBatchLoading &&
              isstudentPerformanceInRangeByBatchSuccess &&
              graphData.length === 0 && <span>No Data Found</span>}
          </Box>
        </Box>

        <Box display={"flex"} flexDirection="row" justifyContent="end" gap={2}>
          <FormControl>
            <Select
              value={examType}
              onChange={handleExamTypeChange}
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2rem",
                borderRadius: "0.5rem",
                bgcolor: "#fafafb",
                borderColor: "#E6EDFF"
              }}
            >
              <MenuItem key={0} value={"All Exams"}>
                All Exams
              </MenuItem>
              {totalExams.map((exam, index) => (
                <MenuItem key={index + 1} value={exam}>
                  {exam}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isbatchesByStudentSuccess && (
            <FormControl>
              <Select
                value={batchType}
                onChange={handleBatchTypeChange}
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                <MenuItem key={0} value={"All Batches"}>
                  All Batches
                </MenuItem>
                {batchNames &&
                  batchNames.map((batch, index) => (
                    <MenuItem key={index + 1} value={batch}>
                      {batch}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          )}
          <Box
            display="flex"
            borderRadius="0.5rem"
            justifyContent="space-between"
            p="5px"
            gap="5px"
            fontSize="10px"
            alignItems="center"
            bgcolor="#FAFAFB;"
            border="1px solid #E6EDFF"
            height="2rem"
          >
            <IconButton onClick={handleBackwardClick} sx={{ padding: "0px" }} size="6px">
              <ArrowBackIcon fontSize="6px" />
            </IconButton>
            <Box textAlign="center" whiteSpace="nowrap" justifySelf="center" padding="0px" sx={{ cursor: "default" }}>
              {date.startDate.toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "numeric" })}-
              {date.endDate.toLocaleDateString("en-GB", { year: "numeric", month: "short", day: "numeric" })}
            </Box>
            <IconButton onClick={handleForwardClick} sx={{ padding: "0px" }} size="6px">
              <ArrowForwardIcon fontSize="6px" />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box mt="1rem">
        {/* Recharts graph */}
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#c8d6ff" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#c8d6ff" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#89bbac" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#89bbac" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorHighest" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#ffcbc8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#ffcbc8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="exam_name" />
            <YAxis />
            <Tooltip />
            <Area type="monotone" dataKey="student_percentage" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            <Area type="monotone" dataKey="class_average" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)" />
            <Area type="monotone" dataKey="class_highest" stroke="#FF3B30" fillOpacity={1} fill="url(#colorHighest)" />
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default OverallAnalysis;
