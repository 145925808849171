import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Blog from "./Blog";

const BlogPage = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full gap-12 lg:gap-0">
      <Header home={false} />
      {/* <Solutions /> */}
      <Blog />
      <Footer />
    </div>
  );
};

export default BlogPage;
