import { Box, Typography } from "@mui/material";
import React from "react";

const Settings = () => {
  return (
    <Box width="100%" bgcolor="#fff" borderRadius="1rem" border="1px solid #E6EDFF" p="1rem">
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Settings
      </Typography>
    </Box>
  );
};

export default Settings;
