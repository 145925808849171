import { Box, Typography } from "@mui/material";
import React from "react";
import DifficultyAnalysisGraph from "./GraphsComponent/DifficultyAnalysisGraph";

const DifficultyAnalysis = ({ studentAnalysisDetails }) => {
  return (
    <Box display="flex" flexDirection="row" width="100%" height="100%" marginTop="1rem">
      <Box width={"100%"} display={"flex"} gap={2}>
        <Box
          width={"60%"}
          display="flex"
          flexDirection="column"
          bgcolor="#fff"
          border="1px solid #E6EDFF"
          height="100%"
          p="1rem 1rem"
          gap={2}
          borderRadius="0.5rem"
          flexWrap="wrap"
        >
          <h1 className="font-medium text-[24px] m-0">Difficulty Analysis</h1>
          <DifficultyAnalysisGraph studentAnalysisDetails={studentAnalysisDetails} />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#fff"
          border="1px solid #E6EDFF"
          width="40%"
          p="1rem 1rem"
          gap={2}
          borderRadius="0.5rem"
          flexWrap="wrap"
        >
          <Typography variant="h4" fontWeight="medium">
            How Difficulty Analysis works?
          </Typography>
          <Typography variant="subtitle1">
            ScaleYou’s algorithms assess exam question difficulty by categorizing them into five levels. A radar graph
            then visually represents this analysis, with each axis showing a difficulty level and the area under the
            graph reflecting the number of questions, offering a clear view of the exam's overall challenge.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default DifficultyAnalysis;
