import React from "react";

const Blog = () => {
  return (
    <main className="relative flex flex-col gap-8 lg:gap-20 w-full">
      <div className="flex flex-col justify-start items-center gap-0">
        <h1 className="font-DMSans tracking-wide leading-[0px] text-[24px] font-semibold text-[#4A3AFF]">
          DEMO VIDEOS
        </h1>
        <h2 className="font-DMSans font-bold text-[50px] leading-[30px] text-[#170F49]">Explore Our Platform</h2>
        <h4 className="font-DMSans font-normal text-wrap text-[18px] leading-[30px] text-center text-[#6F6C90]">These three videos clearly explain our product and reinforce our “institute-first platform” commitment, showing how AI combined with traditional education enhances efficiency for students, faculty, and the entire institute.</h4>
      </div>
    </main>
  );
};

export default Blog;
