import { Box, Button, useMediaQuery } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

import {
  footerBackground,
  footerBackgroundM,
  footerIcon1,
  footerIcon2,
  footerIcon3,
  footerIcon4,
  footerIcon5,
  footerLogo,
  footerQrCode
} from "../../Assets/landing";

const Footer = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  return (
    <div className="flex flex-col items-center w-full bg-[#EBF7FF] lg:mt-16 overflow-hidden">
      <section className="flex items-center w-full z-10 pt-8 lg:pt-16 bg-[#EBF7FF] bg-transparent">
        <div className="container px-4 lg:px-20 flex flex-col items-center w-full gap-4 lg:gap-8 p-4">
          <div className="flex flex-col items-center text-center w-[80%] lg:w-[150%] gap-2">
            <h2 className="m-0 font-semibold text-2xl lg:text-4xl text-wrap md:text-nowrap text-[#14358A] w-full lg:w-1/2">
              Let's start growing your Institute
            </h2>
            <p className="m-0 text-[#14358A] text-opacity-70 text-sm lg:text-sm w-[80%] md:w-[120%] lg:w-1/2 opacity-80">
              Onboard your institute in 10 minutes and start growing with Scaleyou-Insights, the next-gen AI Exam
              Analyzer and LMS.
            </p>
          </div>

          <div className="flex justify-center items-center gap-4">
            <Button
              LinkComponent={Link}
              to="/"
              variant="text"
              className="px-6 py-2 bg-[#FF981F] text-white text-base capitalize"
            >
              Register Now
            </Button>
            <Button LinkComponent={Link} to="/" variant="outlined" className="px-6 py-2  text-base capitalize">
              Contact Us
            </Button>
          </div>
        </div>
      </section>

      <Box
        sx={{
          backgroundImage: isNonMobile ? `url(${footerBackground})` : `url(${footerBackgroundM})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "top",
          width: "100%",
          height: isNonMobile ? "110vh" : "120vh",
          backgroundColor: "#EBF7FF",
          marginTop: "-4rem",
          marginBottom: "-2rem"
        }}
        className="relative flex flex-col justify-center items-center w-full bg-[#EBF7FF] pt-8 lg:pt-16 overflow-hidden"
      >
        {/* <div className="absolute bottom-0 w-full z-10">
          <div className=" w-full">
            <img
              src={footerBackground}
              title="footerBackGround"
              alt=""
              height="100%"
              width="100%"
              className="object-center w-full hidden lg:block"
            />
            <img
              src={footerBackgroundM}
              title="footerBackGroundMobileversion"
              alt=""
              height="100%"
              width="100%"
              className="object-contain w-full float-right lg:hidden"
            />
          </div>
        </div> */}

        <div className="z-30 w-full mt-[5%] flex justify-center items-center">
          <section className="w-full flex justify-center items-center">
            <footer className="container px-4 lg:px-20 flex flex-col justify-center items-center w-full gap-4 lg:gap-8">
              <div className="flex flex-col lg:flex-row justify-between items-center w-full gap-4 lg:gap-8">
                <section className="w-full lg:w-1/4 space-y-2 lg:space-y-4 md:mt-10">
                  <div>
                    <Link to="/" className="flex justify-center lg:justify-start items-center">
                      <div className=" w-40">
                        <img
                          src={footerLogo}
                          title="footer-ScaleYou-Logo"
                          alt="Insights Logo"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                    <h4 className="text-white text-center font-normal text-sm m-0">
                      Scaleyou Insights{"\n"}Empowering You with Data-Driven Success!
                    </h4>
                  </div>
                  {/* <div className="w-full ">
                    <h3 className="text-white font-normal text-opacity-80 text-center lg:text-start m-0 p-0">
                      Scaleyou Insights Empowering You with Data-Driven Success!
                    </h3>
                  </div> */}
                  <div className="flex justify-center lg:justify-start items-center gap-2">
                    <Link to="https://www.scaleyou.net/" target="_blank">
                      <div className="w-12">
                        <img
                          src={footerIcon1}
                          title="ScaleYou-at-web"
                          alt="Icon depicting ScaleYou insights exists in the web"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                    <Link to="https://www.instagram.com/scaleyou_/" target="_blank">
                      <div className="w-12">
                        <img
                          src={footerIcon2}
                          title="ScaleYou-at-Instagram"
                          alt="Icon to navigate to ScaleYou Instagram page"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                    <Link to="https://x.com/Scaleyou_LLP" target="_blank">
                      <div className="w-12">
                        <img
                          src={footerIcon3}
                          title="ScaleYou-at-X"
                          alt="Icon to navigate to ScaleYou X page"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                    <Link to="http://www.google.com/search?q=ScaleYou" target="_blank">
                      <div className="w-12">
                        <img
                          src={footerIcon4}
                          title="ScaleYou-at-Google"
                          alt="Icon to navigate to ScaleYou Google page"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                    <Link to="https://www.linkedin.com/company/scaleyou-net/" target="_blank">
                      <div className="w-12">
                        <img
                          src={footerIcon5}
                          title="ScaleYou-at-LinkedIn"
                          alt="Icon to navigate to ScaleYou LinkedIn page"
                          height="100%"
                          width="100%"
                          className="object-contain w-full"
                        />
                      </div>
                    </Link>
                  </div>
                </section>

                <section className="flex justify-between items-center w-full lg:w-3/4">
                  <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 lg:gap-8">
                    <div className="col-span-2 lg:col-span-1 flex-col hidden md:flex justify-center items-center">
                      <div className="w-32">
                        <img
                          src={footerQrCode}
                          title="QR-code-to-navigate-to-dashboard"
                          height="100%"
                          width="100%"
                          alt="QR code to navigate to the dashboard of at ScaleYou Insights"
                          className="object-contain w-full"
                        />
                      </div>
                      <div className="lg:w-full">
                        <p className="m-0 p-0 text-white text-center">Scan QR Now to start using scaleyou Insights</p>
                      </div>
                    </div>

                    <div className="flex flex-col justify-start items-start lg:items-center">
                      <div className="flex flex-col justify-start items-start gap-2 lg:gap-4">
                        <p className="text-white font-semibold ">Pages</p>
                        <Link to="" className="text-white no-underline text-sm">
                          Home
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Solutions
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Pricing
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Blogs
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Jobs
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Login
                        </Link>
                        <Link to="" className="text-white no-underline text-sm">
                          Signup
                        </Link>
                      </div>
                    </div>

                    <div className="flex flex-col justify-start items-start lg:items-start">
                      <div className="flex flex-col justify-start items-start gap-2 lg:gap-4 text-white lg:w-[120%]">
                        <p className="text-white font-semibold ">Contact Sales</p>
                        <p className="m-0 text-[#ACACAC]">[Only for Institutes, Colleges & Coaching Centers]</p>
                        <p className="m-0">Call +91 6362278745</p>
                        <p className="m-0">Email Id - insights@scaleyou.in</p>
                        <p className="m-0 text-[#ACACAC]">
                          Students DO NOT call or email, please raise issue with you Institutes
                        </p>
                        <p className="m-0">Scaleyou Insights, Bengaluru India</p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="w-full">
                <div className="w-full h-px bg-white bg-opacity-80" />

                <div className="flex flex-col lg:flex-row justify-center items-center gap-2 py-2 lg:py-4">
                  <p className="m-0 p-0  text-[#F1F1F3] text-center">
                    All Rights Reserved © {new Date().getFullYear()} ScaleYou. Powered and Secured by{" "}
                    <Link to={"/"} className="text-[#FF981F]">
                      ScaleYou
                    </Link>
                  </p>
                  <div className="flex justify-center items-center gap-2 text-[#F1F1F3]">
                    <span>|</span>
                    <Link to={"/"} className="text-[#F1F1F3]">
                      Terms and Conditions
                    </Link>
                    <span>|</span>
                    <Link to={"/"} className="text-[#F1F1F3]">
                      Refund Policy
                    </Link>
                    <span>|</span>
                    <Link to={"/"} className="text-[#F1F1F3]">
                      Privacy Policy
                    </Link>
                  </div>
                </div>
              </div>
            </footer>
          </section>
        </div>
      </Box>
    </div>
  );
};

export default Footer;
