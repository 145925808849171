import { Box, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

const SectionAnalysisGraph = ({
  subjectAlignment,
  sectionAnalysisData,
  syllabusData,
  mainColors = ["#8979FF", "#FF928A", "#3CC3DF", "#FFAE4C"]
}) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [filteredSectionData, setFilteredSectionData] = useState([]);
  const [highestTotal, setHighestTotal] = useState(0);

  useEffect(() => {
    if (syllabusData && subjectAlignment && sectionAnalysisData) {
      const filteredData = Object.keys(syllabusData[subjectAlignment] || {})
        .filter(section => section in sectionAnalysisData)
        .map(section => ({
          ...sectionAnalysisData[section],
          sectionName: section
        }));

      setFilteredSectionData(filteredData);

      // Calculate highest total after filtering
      const maxTotal = filteredData.reduce((max, section) => (section.total > max ? section.total : max), 0);
      setHighestTotal(maxTotal);
    }
  }, [syllabusData, subjectAlignment, sectionAnalysisData]);

  const numberSection = [0, 20, 40, 60, 80, 100];

  const VerticalLine = ({ left }) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: left,
        width: "1px",
        borderLeft: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: top,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const GraphScaleDisplay = ({ highestTotal }) => {
    // Ensure highestTotal is at least 1 for proper scale division
    const maxTotal = Math.max(1, highestTotal); // Prevent division by zero

    // Calculate the step size by dividing the maxTotal by 5 and rounding up
    const step = Math.ceil(maxTotal / 5);

    // Determine the length of numberSection based on highestTotal
    const length = highestTotal < 5 ? highestTotal + 1 : 6; // +1 to include 0

    // Generate an array of values based on the length
    const numberSection = Array.from({ length }, (_, i) => i * step);

    return (
      <div className="h-full">
        {numberSection.map((item, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              bottom: `${(index / (numberSection.length - 1)) * 100}%`, // Distribute items from the bottom up
              transform: "translateY(50%) translateX(-100%)", // Center vertically relative to the position
              left: -4 // Offset to the left to display numbers outside the graph area
            }}
          >
            {item}
          </div>
        ))}
      </div>
    );
  };

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      style={{
        flex: 1,
        position: "absolute",
        backgroundColor: color,
        width: "100%",
        opacity: 0.3,
        height: "100%"
      }}
    />
  );

  const MainBar = ({ height, color, label }) => (
    <Box
      sx={{
        position: "absolute",
        bottom: "0%",
        width: "100%",
        height: `${height}%`,
        backgroundColor: color,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        color: "#fff"
      }}
    >
      {label > 0 && <span>{label}</span>}
    </Box>
  );

  return (
    <Box p="1rem 2rem" position="relative">
      <Box
        position="relative"
        minHeight="40vh"
        width="100%"
        bgcolor="#fff"
        border="1px dashed #e3e3e3"
        borderBottom="1px solid #000"
      >
        {/* Render vertical lines for each section */}
        {filteredSectionData.length > 0 &&
          filteredSectionData.map((_, index) => (
            <VerticalLine key={index} left={`${(index + 1) * (100 / filteredSectionData.length)}%`} />
          ))}

        {/* Render horizontal lines */}
        <HorizontalLine top="20%" />
        <HorizontalLine top="40%" />
        <HorizontalLine top="60%" />
        <HorizontalLine top="80%" />

        <GraphScaleDisplay highestTotal={highestTotal} />

        {/* Render bars for each section */}
        <Box
          display="flex"
          justifyContent="space-evenly"
          bgcolor={"transparent"}
          position="absolute"
          zIndex={100}
          top={0}
          left={0}
          right={0}
          bottom={0}
        >
          {filteredSectionData.length > 0 ? (
            filteredSectionData.map((sectionData, sectionIndex) => {
              const unanswered = sectionData.total - sectionData.correct - sectionData.wrong;
              return (
                <Box
                  key={sectionIndex}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="flex-end"
                  flex={1}
                  height="100%" // Ensure height covers the full available space
                  bgcolor="transparent"
                  width="100%"
                >
                  <Box
                    display="flex"
                    width="70%"
                    height="100%"
                    flexDirection="row"
                    alignItems="flex-center"
                    position="relative"
                    gap={"0.5%"}
                    bottom={0}
                  >
                    {/* Total Questions Bar */}
                    <Box position="relative" width="25%">
                      <BackgroundBoxOfGraph color={mainColors[0]} />
                      <MainBar
                        height={(sectionData.total / (highestTotal || 1)) * 100}
                        color={mainColors[0]}
                        label={sectionData.total}
                      />
                    </Box>

                    {/* Correct Answers Bar */}
                    <Box position="relative" width="25%">
                      <BackgroundBoxOfGraph color={mainColors[1]} />
                      <MainBar
                        height={(sectionData.correct / (highestTotal || 1)) * 100}
                        color={mainColors[1]}
                        label={sectionData.correct}
                      />
                    </Box>

                    {/* Wrong Answers Bar */}
                    <Box position="relative" width="25%">
                      <BackgroundBoxOfGraph color={mainColors[2]} />
                      <MainBar
                        height={(sectionData.wrong / (highestTotal || 1)) * 100}
                        color={mainColors[2]}
                        label={sectionData.wrong}
                      />
                    </Box>

                    {/* Unanswered Bar */}
                    <Box position="relative" width="25%">
                      <BackgroundBoxOfGraph color={mainColors[3]} />
                      <MainBar
                        height={(unanswered / (highestTotal || 1)) * 100}
                        color={mainColors[3]}
                        label={unanswered}
                      />
                    </Box>
                  </Box>

                  {/* Section name */}
                  <Box
                    position="absolute"
                    display="flex"
                    justifyContent="space-around"
                    top={"110%"}
                    sx={{ flexWrap: "nowrap" }}
                  >
                    <Tooltip title={sectionData?.sectionName}>
                      <Box
                        width="50%"
                        display="inline-block"
                        justifyContent="space-evenly"
                        gap={5}
                        sx={{ textWrap: "nowrap", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}
                      >
                        {sectionData?.sectionName}
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography>Loading</Typography>
          )}
        </Box>
      </Box>

      {/* Legend */}
      <Box display="flex" flexDirection="row" justifyContent="center" gap="2rem" alignItems="center" mt="5rem">
        {mainColors.slice(0, 4).map((color, index) => (
          <Box key={index} display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
            <Box width="0.5rem" height="0.5rem" bgcolor={color} />
            <Box>{["Number Of Questions", "Answered Correctly", "Answered Incorrectly", "Unanswered"][index]}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default SectionAnalysisGraph;
