// inbuilt modules
import React, { useEffect, useState } from "react";

// external modules
import { Helmet } from "react-helmet-async";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useGetAllStudentAnalysisQuery } from "../../../redux/api/analysisSlice";
import { useGetStudentResultByStudentIdQuery } from "../../../redux/api/resultsSlice";

// internal modules
import TotalStats from "../../../components/Student/Dashboard/Totalstats";
import LineGraph from "../../../components/Student/Dashboard/graph";
import TestHistoryStudent from "../../../components/Student/Dashboard/testhistory";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";
import InsightsDashaBoardAnalysis from "../../../components/Student/Dashboard/InsightsAnalysis";
import SubjectAnalysis from "../../../components/Student/Dashboard/SubjectAnalysis";
import SuperInsights from "../../../components/Student/Dashboard/SuperInsights";
import OverallAnalysis from "../../../components/Student/Dashboard/OverallAnalysis";

const StudentDashboard = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();

  // redux
  const { user } = useSelector(state => state.auth);
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);
  const { data: boardData, isSuccess: isBoardSuccess } = useGetStudentResultByStudentIdQuery({
    studentId: user._id,
    schoolId: user.school_id
  });

  let [totalExams, setTotalExams] = useState([]);

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) {
          if (exam.exam_for !== "undefined") uniqueExams.add(exam.exam_for);
        }
      });
      setTotalExams(Array.from(uniqueExams));
    }
  }, [analysisData]);

  // render
  return (
    <Box
      padding={`1rem ${isNonMobile ? 2 : 0}rem`}
      display="flex"
      flexDirection="column"
      gap="1rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <Box display="flex" gap="0.75rem" flexDirection="column">
        <Typography variant="h2" fontWeight={600} color={theme.palette.primary.dark}>
          Welcome Back, {user.name}
        </Typography>
        <Typography variant="subtitle1" color={theme.palette.primary.light} style={{ fontSize: "17px" }}>
          Here you can get all the information of yourself
        </Typography>
      </Box>
      <TotalStats />
      {isAnalysisSuccess && totalExams.length > 0 && user && (
        <OverallAnalysis userData={user} analysisData={analysisData} totalExams={totalExams} />
      )}
      <SuperInsights />
      {/* <LineGraph /> */}
      {user && <InsightsDashaBoardAnalysis userData={user} />}
      <TestHistoryStudent />
      <DashboardDisplay who="student" />
      {/* Graph section */}
      {isAnalysisSuccess && <SubjectAnalysis analysisData={analysisData} />}
      {isAnalysisSuccess && isBoardSuccess && (
        <SubjectAnalysis analysisData={analysisData} boardData={boardData} typeBoard={true} />
      )}
    </Box>
  );
};

// render
export default StudentDashboard;
