import { Box, FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import {
  useGetBatchesByFacultyWithExamTypesQuery,
  useGetBatchesByStudentWithXpForFacutlyQuery
} from "../../../redux/api/batchSlice";
import { useGetLatestResultWithRankbyBatchForFacultyQuery } from "../../../redux/api/resultsSlice";

const InsightsDashaBoardAnalysisForFaculty = ({ userData }) => {
  const [examType, setExamType] = useState(0);
  const [batchforCompetitiveExam, setBatchforCompetitiveExam] = useState(0);
  const [batchforBoardExam, setBatchforBoardExam] = useState(0);

  const { data: allBatchesWithExamTypes, isSuccess: allBatchesFetchingSuccess } =
    useGetBatchesByFacultyWithExamTypesQuery({
      school_id: userData?.school_id,
      faculty_id: userData?._id
    });

  const { data: studentsWithTheirXpForFaculty, isSuccess: studentsWithTheirXpSuccess } =
    useGetBatchesByStudentWithXpForFacutlyQuery(
      {
        schoolId: userData?.school_id,
        examType: allBatchesWithExamTypes?.uniqueExamTypes[examType],
        batchId:
          allBatchesFetchingSuccess && allBatchesWithExamTypes?.batches?.length > 0
            ? allBatchesWithExamTypes.batches[batchforCompetitiveExam]?._id
            : undefined
      },
      {
        skip:
          !allBatchesFetchingSuccess ||
          !allBatchesWithExamTypes?.batches?.length ||
          !allBatchesWithExamTypes?.uniqueExamTypes?.length
      }
    );

  const {
    data: batchForBoard,
    isSuccess: batchForBoardSuccess,
    isLoading: batchForBoardLoading
  } = useGetLatestResultWithRankbyBatchForFacultyQuery(
    {
      schoolId: userData?.school_id,
      batchId:
        allBatchesFetchingSuccess && allBatchesWithExamTypes?.batches?.length > 0
          ? allBatchesWithExamTypes.batches[batchforBoardExam]?._id
          : undefined
    },
    {
      skip: !allBatchesFetchingSuccess || !allBatchesWithExamTypes?.batches?.length || !userData.examType?.length
    }
  );

  const handleExamTypeChange = event => {
    setExamType(event.target.value);
  };

  const handleBatchforCompetitiveExamChange = event => {
    setBatchforCompetitiveExam(event.target.value);
  };

  const handleBatchforBoardExamChange = event => {
    setBatchforBoardExam(event.target.value);
  };

  const renderTopPerformingStudents = () => (
    <Box width="100%" position="relative" bgcolor="#fff" borderRadius="1rem" border="1px solid #E6EDFF" p="1rem 1rem">
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <h3 className="font-semibold">Top Performing Students</h3>
        <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" gap={4}>
          {allBatchesFetchingSuccess && (
            <FormControl>
              <Select
                value={examType}
                onChange={handleExamTypeChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  width: "5.2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                {allBatchesWithExamTypes?.uniqueExamTypes?.map((exam, index) => (
                  <MenuItem key={index} value={index}>
                    {exam}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {allBatchesFetchingSuccess && allBatchesWithExamTypes?.batches?.length > 0 && (
            <FormControl>
              <Select
                value={batchforCompetitiveExam}
                onChange={handleBatchforCompetitiveExamChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2rem",
                  width: "5.2rem",
                  borderRadius: "0.5rem",
                  bgcolor: "#fafafb",
                  borderColor: "#E6EDFF"
                }}
              >
                {allBatchesWithExamTypes.batches.map((batch, index) => (
                  <MenuItem value={index} key={index}>
                    {batch.batch_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
      </Box>
      <Box>
        <table className="w-[100%] h-[100%]">
          <thead>
            <tr className="flex justify-around bg-[#eaeaea] rounded-t-xl h-[3rem]">
              <th className="text-center ml-5 flex items-center text-lg text-[#030229]">Total XP</th>
              <th className="text-center flex items-center text-lg text-[#030229]">Student Name</th>
            </tr>
          </thead>
          <tbody>
            {studentsWithTheirXpSuccess && studentsWithTheirXpForFaculty?.length > 0 ? (
              studentsWithTheirXpForFaculty.map((student, index) => (
                <tr
                  key={index}
                  className={`flex rounded-xl justify-between h-[3rem] items-center ${
                    userData?._id === student.studentId ? "bg-[#f8f8f8]" : ""
                  }`}
                >
                  <td className="w-1/2 text-center">{student?.xp}</td>
                  <td className="w-1/2 text-center">{student?.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );

  const renderTopPerformingStudentsInBoards = () => (
    <Box width="100%" position="relative" bgcolor="#fff" borderRadius="1rem" border="1px solid #E6EDFF" p="1rem 1rem">
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <h3 className="font-semibold">Top Performing Students in Boards</h3>
        {allBatchesFetchingSuccess && allBatchesWithExamTypes?.batches?.length > 0 && (
          <FormControl>
            <Select
              value={batchforBoardExam}
              onChange={handleBatchforBoardExamChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2rem",
                width: "5.2rem",
                borderRadius: "0.5rem",
                bgcolor: "#fafafb",
                borderColor: "#E6EDFF"
              }}
            >
              {allBatchesWithExamTypes.batches.map((batch, index) => (
                <MenuItem value={index} key={index}>
                  {batch.batch_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>
      <Box>
        <table className="w-[100%]">
          <thead>
            <tr className="flex justify-around bg-[#eaeaea] rounded-t-xl h-[3rem]">
              <th className="text-center ml-6 flex items-center text-lg text-[#030229]">Rank</th>
              <th className="text-center flex items-center text-lg text-[#030229]">Student Name</th>
            </tr>
          </thead>
          <tbody>
            {batchForBoardLoading ? (
              <tr>
                <td colSpan="2" className="text-center">
                  Loading...
                </td>
              </tr>
            ) : batchForBoardSuccess && batchForBoard?.length > 0 ? (
              batchForBoard.map((item, index) => (
                <tr
                  key={index}
                  className={`flex rounded-xl justify-between h-[3rem] items-center ${
                    userData?._id === item.studentId ? "bg-[#f8f8f8]" : ""
                  }`}
                >
                  <td className="w-1/2 text-center">{item.rank}</td>
                  <td className="w-1/2 text-center">{item.name}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </Box>
    </Box>
  );

  return (
    <div className="flex flex-col gap-4 w-[100%]">
      <section className="flex flex-row gap-4">
        {renderTopPerformingStudents()}
        {renderTopPerformingStudentsInBoards()}
      </section>
    </div>
  );
};

export default InsightsDashaBoardAnalysisForFaculty;
