import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, IconButton, styled, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import correctAns from "../../../Assets/StudentAnalysis/correctly-answered.png";
import Rank from "../../../Assets/StudentAnalysis/Rank.png";
import totalQues from "../../../Assets/StudentAnalysis/total-questions.png";
import unanswered from "../../../Assets/StudentAnalysis/Unanswered.png";
import wrongAns from "../../../Assets/StudentAnalysis/wrong-answered.png";
import { useGetEntireSyllabusQuery } from "../../../redux/api/syllabusSlice";
import ChapterAnalysis from "./ChapterAnalysis";
import DifficultyAnalysis from "./DifficultyAnalysis";
import SectionAnalysis from "./SectionAnalysis";
import StrengthAnalysis from "./StrengthAnalysis";
// import DifficultyAnalysisGraphTest from "./GraphsComponent/DifficultyAnalysisGraphTest";

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.2rem",
    color: "#fff"
  }
}));

const StyledToggleButtonForSubjects = styled(ToggleButton)(({ theme }) => ({
  "&.MuiToggleButton-root.Mui-selected": {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: "0.5rem",
    color: "#fff"
  }
}));

const DetailedAnalysis = ({ analysisData }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const { analysisId } = useParams();
  const { user } = useSelector(state => state.auth);

  const [alignment, setAlignment] = useState("chapter");
  const [subjectAlignment, setSubjectAlignment] = useState("");
  const [rank, setRank] = useState(0);
  const [examForDetailedAnalysis, setExamForDetailedAnalysis] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [visibleSubjects, setVisibleSubjects] = useState([0, 1]); // Keep track of the visible subject range
  const [subjects, setSubjects] = useState([]);

  const handleScrollLeft = () => {
    if (visibleSubjects[0] > 0) {
      setVisibleSubjects([visibleSubjects[0] - 1, visibleSubjects[1] - 1]);
    }
  };

  const handleScrollRight = () => {
    if (visibleSubjects[1] < subjects.length - 1) {
      setVisibleSubjects([visibleSubjects[0] + 1, visibleSubjects[1] + 1]);
    }
  };

  const { data: syllabusData, isSuccess: isSyllabusSuccess } = useGetEntireSyllabusQuery(user?.school_id);
  const [studentAnalysisData, setStudentAnalysisData] = useState([
    { TotalQuestion: 0, url: totalQues },
    { CorrectAns: 0, url: correctAns },
    { WrongAns: 0, url: wrongAns },
    { Unanswered: 0, url: unanswered },
    { Rank: 0, url: Rank }
  ]);

  useEffect(() => {
    if (analysisData && analysisData.sectionAnalysis) {
      if (Object.keys(analysisData.sectionAnalysis).length && alignment !== "") {
        setSelectedItem(Object.keys(analysisData.sectionAnalysis)[0]);
      } else {
        setSelectedItem("");
      }
    }
  }, [analysisData, alignment]);

  const handleMenuItemChange = event => {
    setSelectedItem(event.target.value);
  };

  useEffect(() => {
    if (analysisId && analysisData) {
      // Sort exams by student score in ascending order
      const sortedExams = [...analysisData.analysisArray].sort(
        (examA, examB) => examA.student_score - examB.student_score
      );

      // Find the analysisData data for the current exam
      const currentExam = sortedExams.find((exam, index) => {
        if (exam._id === analysisId) {
          const newStudentAnalysisData = [
            { TotalQuestion: exam.total_questions, url: totalQues },
            { CorrectAns: Object.values(exam.subjectAnalysis)[0]?.correct || 0, url: correctAns },
            { WrongAns: Object.values(exam.subjectAnalysis)[0]?.wrong || 0, url: wrongAns },
            {
              Unanswered:
                (Object.values(exam.subjectAnalysis)[0]?.total || 0) -
                (Object.values(exam.subjectAnalysis)[0]?.correct || 0) -
                (Object.values(exam.subjectAnalysis)[0]?.wrong || 0),
              url: unanswered
            },
            { Rank: index + 1, url: Rank }
          ];

          setStudentAnalysisData(newStudentAnalysisData);

          return true;
        }
        return false;
      });

      setExamForDetailedAnalysis([currentExam]);

      // Set initial subject alignment if not set
      if (currentExam && !subjectAlignment) {
        const initialSubject = Object.keys(currentExam.subjectAnalysis)[0];
        if (initialSubject) {
          setSubjectAlignment(initialSubject);
        }
      }
    }
  }, [analysisData, analysisId]);

  useEffect(() => {
    if (isSyllabusSuccess && examForDetailedAnalysis.length > 0 && syllabusData) {
      const subjectOfStudents = Object.keys(examForDetailedAnalysis[0]?.subjectAnalysis || {});
      const syllabusSubjects = Object.keys(syllabusData);
      const validSubjects = subjectOfStudents.filter(subject => syllabusSubjects.includes(subject));
      setSubjects(validSubjects);
    }
  }, [isSyllabusSuccess, examForDetailedAnalysis, syllabusData]);

  const handleToggle = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleSubjectToggle = (event, newSubjectAlignment) => {
    if (newSubjectAlignment !== null) {
      setSubjectAlignment(newSubjectAlignment);
    }
  };

  if (!analysisData || !examForDetailedAnalysis || !examForDetailedAnalysis[0]?.subjectAnalysis) {
    return <Typography variant="h6">No data available</Typography>;
  }

  const sectionAnalysisData = examForDetailedAnalysis[0]?.sectionAnalysis;
  const chapterAnalysisData = examForDetailedAnalysis[0]?.chapterAnalysis;

  return (
    <Box>
      {/** Subject Toggle Button */}
      <Box display="flex" width={isNonMobile ? "100%" : "80%"} paddingTop="20px" justifyContent="center">
        <ToggleButtonGroup
          sx={{
            bgcolor: "#fff",
            width: isNonMobile ? "80%" : "100%"
          }}
          value={alignment}
          exclusive
          onChange={handleToggle}
        >
          <StyledToggleButton value="chapter" sx={{ width: "100%" }}>
            <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
              Detailed Chapter Report
            </Typography>
          </StyledToggleButton>
          <StyledToggleButton value="student" sx={{ width: "100%" }}>
            <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
              LeaderBoard
            </Typography>
          </StyledToggleButton>
        </ToggleButtonGroup>
      </Box>
      <section className="mt-[5%]">
        <Typography textAlign="center" variant="h3" fontStyle="medium" fontSize="28px">
          Detailed Analysis
        </Typography>
        <Typography textAlign="center" color="#4F4E69" variant="subtitle1" fontStyle="medium" fontSize="13px">
          A comprehensive analysisData of each subject’s sections, including detailed{" "}
          <span className="text-[#0900FF] font-semibold">insights</span> and tailored recommendations <br /> for
          targeted <span className="text-[#0900FF] font-semibold">improvement</span>.
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          gap="0.75rem"
          bgcolor="#fff"
          border="1px solid #E6EDFF"
          width="100%"
          height="100%"
          mt="1rem"
          p="1rem 1rem"
          borderRadius="0.5rem"
        >
          {subjects?.length === 0 && (
            <Typography width="100%" textAlign="center" alignSelf="center" variant="h6">
              No <span style={{ color: "#0900ff" }}>Subjects</span> To Display
            </Typography>
          )}
          {subjects.length > 2 && (
            <IconButton onClick={handleScrollLeft} disabled={visibleSubjects[0] === 0}>
              <ArrowBackIos />
            </IconButton>
          )}

          {subjects?.length !== 0 && (
            <Box
              display="flex"
              overflow="hidden"
              sx={{
                width: isNonMobile ? "50%" : "80%",
                maxWidth: "600px"
              }}
            >
              <ToggleButtonGroup
                sx={{
                  bgcolor: "#E9E5E5",
                  width: "100%",
                  height: "10%",
                  borderRadius: "0.5rem"
                }}
                value={subjectAlignment}
                exclusive
                onChange={handleSubjectToggle}
              >
                {subjects.slice(visibleSubjects[0], visibleSubjects[1] + 1).map(subject => (
                  <StyledToggleButtonForSubjects value={subject} key={subject} sx={{ width: "100%" }}>
                    <Typography variant={isNonMobile ? "h5" : "h6"} fontWeight={500}>
                      {subject}
                    </Typography>
                  </StyledToggleButtonForSubjects>
                ))}
              </ToggleButtonGroup>
            </Box>
          )}

          {/* Render scroll buttons only if there are more than 2 subjects */}
          {subjects.length > 2 && (
            <IconButton onClick={handleScrollRight} disabled={visibleSubjects[1] === subjects.length - 1}>
              <ArrowForwardIos />
            </IconButton>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          bgcolor="#fff"
          border="1px solid #E6EDFF"
          width="100%"
          height="100%"
          mt="1rem"
          p="1rem 1rem"
          gap={2}
          borderRadius="0.5rem"
          flexWrap="wrap"
        >
          <Box display="flex" flexWrap={"wrap"} bgcolor="#fff" borderRadius="0.5rem" gap={1} width="100%">
            {subjects?.length === 0 && (
              <Typography width="100%" textAlign="center" alignSelf="center" variant="h6">
                No Subjects Selected for <span style={{ color: "#0900ff" }}>Section Analysis</span>
              </Typography>
            )}
            {examForDetailedAnalysis &&
              subjects?.length !== 0 &&
              studentAnalysisData.map((data, index) => (
                <Box key={index} display="flex" gap={1} flexDirection="column" alignItems="start" width={"15%"}>
                  {Object.entries(data).map(
                    ([field, value], idx) =>
                      field !== "url" && (
                        <Box
                          key={idx}
                          display="flex"
                          flexDirection="column"
                          alignItems="start"
                          bgcolor="#fff"
                          border="1px solid #E6EDFF"
                          width="100%"
                          mt="1rem"
                          p="1rem"
                          borderRadius="0.5rem"
                        >
                          {/* Image rendering */}
                          <img src={data.url} alt={field} width="24px" height="24px" style={{ marginRight: "10px" }} />

                          {/* Field name */}
                          <Typography variant="subtitle1" color="#949596" noWrap={true}>
                            {field}
                          </Typography>

                          {/* Field value */}
                          <Typography variant="h6" fontWeight="semibold">
                            {value}
                          </Typography>
                        </Box>
                      )
                  )}
                </Box>
              ))}
          </Box>
          {sectionAnalysisData && subjects?.length !== 0 && isSyllabusSuccess && (
            <SectionAnalysis
              sectionAnalysisData={sectionAnalysisData}
              subjectAlignment={subjectAlignment}
              syllabusData={syllabusData}
            />
          )}
        </Box>
        <Box mt="1rem" width="100%">
          {chapterAnalysisData && sectionAnalysisData && subjects?.length !== 0 && isSyllabusSuccess ? (
            <ChapterAnalysis
              sectionAnalysisData={sectionAnalysisData}
              syllabusData={syllabusData}
              chapterAnalysisData={chapterAnalysisData}
              subjectAlignment={subjectAlignment}
            />
          ) : (
            <Typography
              width="100%"
              textAlign="center"
              variant="h6"
              sx={{
                bgcolor: "#fff",
                border: "1px solid #E6EDFF",
                marginTop: "1rem",
                padding: "1rem",
                borderRadius: "0.5rem"
              }}
            >
              No Subjects Selected For <span style={{ color: "#0900ff" }}>Chapter Analysis</span>
            </Typography>
          )}
        </Box>
        {chapterAnalysisData && subjects?.length !== 0 ? (
          <Box>
            <StrengthAnalysis ChapterDetails={chapterAnalysisData} />
          </Box>
        ) : (
          <Typography
            width="100%"
            textAlign="center"
            variant="h6"
            sx={{
              bgcolor: "#fff",
              border: "1px solid #E6EDFF",
              marginTop: "1rem",
              padding: "1rem",
              borderRadius: "0.5rem"
            }}
          >
            No subject Selected for <span style={{ color: "#0900ff" }}>Strength Analysis</span>
          </Typography>
        )}
        {examForDetailedAnalysis && subjects?.length !== 0 ? (
          <Box>
            <DifficultyAnalysis
              studentAnalysisDetails={[
                {
                  subject: "Analysing and Evaluating",
                  A:
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Applying?.wrong ?? 0) +
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Applying?.correct ?? 0)
                },
                {
                  subject: "Creating",
                  A:
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Creating?.wrong ?? 0) +
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Creating?.correct ?? 0)
                },
                {
                  subject: "Remembering",
                  A:
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Remembering?.wrong ?? 0) +
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Remembering?.correct ?? 0)
                },
                {
                  subject: "Understanding",
                  A:
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Understanding?.wrong ??
                      0) +
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.Understanding?.correct ??
                      0)
                },
                {
                  subject: "Applying",
                  A:
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.[
                      "Analysing and Evaluating"
                    ]?.wrong ?? 0) +
                    (examForDetailedAnalysis[0].subjectAnalysis[subjectAlignment].difficulty?.[
                      "Analysing and Evaluating"
                    ]?.correct ?? 0)
                }
              ]}
            />
            {/* <DifficultyAnalysisGraphTest /> */}
          </Box>
        ) : (
          <Typography
            width="100%"
            textAlign="center"
            variant="h6"
            sx={{
              bgcolor: "#fff",
              border: "1px solid #E6EDFF",
              marginTop: "1rem",
              padding: "1rem",
              borderRadius: "0.5rem"
            }}
          >
            No subject selected for <span style={{ color: "#0900ff" }}>Difficulty Anlaysis</span>
          </Typography>
        )}
      </section>
    </Box>
  );
};

export default DetailedAnalysis;
