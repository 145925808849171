import { Box, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { useSelector } from "react-redux";
import { useGetEntireSyllabusQuery } from "../../../../../redux/api/syllabusSlice";

const SectionAnalysis = ({ syllabusAnalysis, subject, section }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const { user } = useSelector(state => state.auth);
  const { data: syllabus, isSuccess: isGetSyllabusSuccess } = useGetEntireSyllabusQuery(user?.school_id);
  const [chapterArray, setChapterArray] = useState([]);
  // Fetch chapter data based on subject and section
  useEffect(() => {
    if (isGetSyllabusSuccess && syllabus && subject && section) {
      const chapters = syllabus[subject]?.[section] || [];
      setChapterArray(chapters);
    } else {
      setChapterArray([]);
    }
  }, [syllabus, subject, section, isGetSyllabusSuccess]);

  // Calculate percentage correctness for chapters
  const getPercentage = (value, total) => {
    if (total === 0) return 0;
    return ((value / total) * 100).toFixed(2);
  };

  const calculateChapterCorrectness = chapter => {
    const chapterData = syllabusAnalysis?.chapterAnalysis?.[chapter] || {};
    const { correct = 0, total = 0 } = chapterData;
    return getPercentage(correct, total);
  };

  return (
    <Box
      display="flex"
      width="100%"
      gap="2rem"
      mt="1rem"
      justifyContent="center"
      flexDirection={isNonMobile ? "row" : "column"}
    >
      {/* Chart Section */}
      <Box
        border="1px solid #E6EDFF"
        p={isNonMobile ? "0.5rem" : "1rem"}
        bgcolor="#FFFFFF"
        borderRadius="1rem"
        width={isNonMobile ? "65%" : "100%"}
      >
        <div style={{ height: "100%", width: "100%" }}>
          <BarChart
            yAxis={[{ label: "Average Correctness (%)", max: 100 }]}
            xAxis={[
              {
                scaleType: "band",
                data: chapterArray.length ? chapterArray.map((_, index) => `${index + 1}`) : ["NaN"],
                label: "Chapters"
              }
            ]}
            series={[
              {
                data: chapterArray.length ? chapterArray.map(chapter => calculateChapterCorrectness(chapter)) : [0]
              }
            ]}
            height={500}
            colors={["#2970FF"]}
          />
        </div>
      </Box>

      {/* Text Section */}
      <Box width={isNonMobile ? "30%" : "100%"}>
        <Typography variant="h3">Chapters:</Typography>
        {chapterArray.length ? (
          <ol style={{ display: "flex", gap: "0.5rem", flexDirection: "column" }}>
            {chapterArray.map((chapterName, index) => (
              <li key={index}>
                <Typography variant="h4">{chapterName}</Typography>
              </li>
            ))}
          </ol>
        ) : (
          <Typography variant="body1">No Chapters found. Please contact supervisor to add chapters.</Typography>
        )}
      </Box>
    </Box>
  );
};

export default SectionAnalysis;
