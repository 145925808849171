import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

export const samlApiSlice = createApi({
  reducerPath: "samlApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/saml`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  endpoints: builder => ({
    initiateSamlLogin: builder.mutation({
      query: userId => ({
        url: "/login",
        method: "POST",
        body: { userId },
        responseHandler: response => response.text() // Handle response as text
      }),
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const data = await queryFulfilled;
          console.log("SAML login initiated successfully:", data);
          // Handle HTML response as needed
        } catch (error) {
          console.error("Error initiating SAML login:", error);
          toast.error("Error initiating SAML login");
        }
      }
    })
  })
});

export const { useInitiateSamlLoginMutation } = samlApiSlice;
