import { Box, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

const ScoreAnalysisGraph = ({ scoreAnalysisData, mainColors = ["#8979FF", "#FF928A", "#3CC3DF"] }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const [sectionDetails, setSectionDetails] = useState([]);

  useEffect(() => {
    if (scoreAnalysisData) {
      setSectionDetails([
        { label: "You", data: scoreAnalysisData.currStudent },
        { label: "Class Average", data: scoreAnalysisData.classAverage },
        { label: "Topper", data: scoreAnalysisData.topper }
      ]);
    }
  }, [scoreAnalysisData]);

  const numberSection = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  let highestTotal = 0;

  sectionDetails.forEach(section => {
    if (section.data.total > highestTotal) {
      highestTotal = section.data.total;
    }
  });

  const VerticalLine = ({ left }) => (
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: `${left}`,
        width: "1px",
        borderLeft: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const HorizontalLine = ({ top }) => (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: `${top}`,
        height: "1px",
        borderTop: "1px dashed #e3e3e3",
        backgroundColor: "#fff"
      }}
    />
  );

  const BackgroundBoxOfGraph = ({ color }) => (
    <div
      style={{
        flex: 1,
        position: "absolute",
        backgroundColor: color,
        width: "100%",
        opacity: 0.3,
        height: "100%"
      }}
    />
  );

  const MainBar = ({ width, color, label }) => (
    <Box
      className="hover-shadow"
      sx={{
        "position": "absolute",
        "bottom": "0%",
        "width": `${width}%`,
        "height": "100%",
        "backgroundColor": color,
        "display": "flex",
        "justifyContent": "end",
        "alignItems": "start",
        "color": "#000000",
        "paddingRight": "0.3rem",
        "transition": "box-shadow 0.3s ease, transform 0.3s ease",
        "zIndex": 1, // Ensure the bar is above the background elements

        // Hover effect for the main bar
        "&:hover": {
          "boxShadow": "inset 0 0 15px rgba(0, 0, 0, 0.7)", // Increased shadow strength for better visibility
          "& span": {
            transform: "scale(1.2)" // Scale up the label
          }
        },
        // Label transition for smooth scaling
        "& span": {
          transition: "transform 0.3s ease"
        }
      }}
    >
      {width >= 5 && <span>{label}</span>}
      {width < 5 && (
        <span
          style={{
            position: "absolute",
            right: width - 10, // Position label outside if width < 10%
            top: "50%",
            transform: "translateY(-50%)", // Center vertically
            transition: "transform 0.3s ease"
          }}
        >
          {label}
        </span>
      )}
    </Box>
  );
  return (
    <Box p="1rem 1rem" position="relative" width="100%" display="flex" flexDirection="column">
      {/* Section Labels and Graphs */}
      <Box width="100%" display="flex" flexDirection="row" gap={1} bgcolor="transparent">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          textAlign="center"
          flex="1"
          height="100%"
        >
          {sectionDetails.map((section, index) => (
            <Box key={index} p="0.5rem 0">
              {section.label}
            </Box>
          ))}
        </Box>

        {/* Graph Container */}
        <Box
          position="relative"
          minHeight="40vh"
          width="100%"
          bgcolor="#fff"
          border="1px dashed #e3e3e3"
          borderLeft="1px solid #000"
        >
          {/* Vertical Lines */}
          {numberSection.map((_, index) => (
            <VerticalLine key={index} left={`${(index + 1) * 10}%`} />
          ))}

          {/* Horizontal Lines */}
          <HorizontalLine top="20%" />
          <HorizontalLine top="40%" />
          <HorizontalLine top="60%" />
          <HorizontalLine top="80%" />

          {/* Render bars for each section */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="start"
            bgcolor={"transparent"}
            position="absolute"
            zIndex={100}
            top={0}
            left={0}
            right={0}
            bottom={0}
          >
            {sectionDetails.map((section, sectionIndex) => {
              const { correct, wrong, total } = section.data;
              const unanswered = total - (correct + wrong);

              return (
                <Box
                  key={sectionIndex}
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                  justifyContent="center"
                  flex={1}
                  height="100%"
                  bgcolor="transparent"
                  width="100%"
                >
                  <Box
                    display="flex"
                    width="100%"
                    height="80%"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="center"
                    position="relative"
                    bgcolor="transparent"
                    gap={"0.5%"}
                    bottom={0}
                  >
                    {/* Correct Answers Bar */}
                    <Box position="relative" height="25%" width="100%">
                      <MainBar width={(correct / (highestTotal || 1)) * 100} color={mainColors[0]} label={correct} />
                      <BackgroundBoxOfGraph color={mainColors[0]} label={correct} />
                    </Box>

                    {/* Wrong Answers Bar */}
                    <Box position="relative" height="25%" width="100%">
                      <MainBar width={(wrong / (highestTotal || 1)) * 100} color={mainColors[1]} label={wrong} />
                      <BackgroundBoxOfGraph color={mainColors[1]} />
                    </Box>

                    {/* Unanswered Bar */}
                    <Box position="relative" height="25%" width="100%">
                      <MainBar
                        width={(unanswered / (highestTotal || 1)) * 100}
                        color={mainColors[2]}
                        label={unanswered}
                      />
                      <BackgroundBoxOfGraph color={mainColors[2]} />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      {/* Legend */}
      <Box display="flex" flexDirection="row" justifyContent="center" gap="2rem" alignItems="center" mt="5rem">
        {mainColors.slice(0, 3).map((color, index) => (
          <Box key={index} display="flex" flexDirection="row" justifyContent="center" gap="8px" alignItems="center">
            <Box width="0.5rem" height="0.5rem" bgcolor={color} />
            <Box>{["Correct", "Wrong", "Unanswered"][index]}</Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ScoreAnalysisGraph;
