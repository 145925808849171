import { Box, Button, FormControl, styled, TextField, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";

const StyledTextField = styled(TextField)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  fontWeight: "400"
}));

const ContactDetails = ({ user, contactDetails, setContactDetails, isEdit, setIsEdit, handleSubmitContactDetails }) => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  const handleChange = event => {
    const { name, value } = event.target;
    setContactDetails(prevDetails => ({
      ...prevDetails,
      [name]: value
    }));
  };

  return (
    <Box
      width="100%"
      bgcolor="#fff"
      borderRadius="1rem"
      border="1px solid #E6EDFF"
      p="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
    >
      <Typography variant="h5" fontSize="18px" fontWeight={500}>
        Contact Details
      </Typography>
      <Box
        width="100%"
        display="flex"
        flexDirection={isNonMobile ? "row" : "column"}
        justifyContent="space-between"
        gap="1.5rem"
      >
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Email *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Email"
              value={contactDetails.email}
              name="email"
              disabled
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.6)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10pxrem">
          <Typography variant="subtitle1">Mobile Number *</Typography>
          <FormControl>
            <StyledTextField
              placeholder="Mobile Number"
              value={contactDetails.phone}
              onChange={handleChange}
              name="phone"
              disabled={!isEdit}
              type="text"
              InputProps={{
                inputProps: { style: { color: "rgba(0,0,0,0.8)", fontSize: "15px", height: "7px" } }
              }}
            />
          </FormControl>
        </Box>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" alignItems="center" gap="1.5rem">
        {isEdit ? (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "lightgreen",
              padding: "5px 10px"
            }}
            onClick={handleSubmitContactDetails}
          >
            Save Details
          </Button>
        ) : (
          <Button
            size="small"
            style={{
              fontSize: "14px",
              backgroundColor: "#3267FF",
              color: "#fff",
              padding: "5px 10px"
            }}
            onClick={() => setIsEdit(true)}
          >
            Edit Details
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ContactDetails;
