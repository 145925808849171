import { useTheme } from "@emotion/react";
import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useGetBatchesByStudentQuery } from "../../../redux/api/batchSlice";
import { useGetSchoolStudentStatsQuery } from "../../../redux/api/performanceSlice";
import { useGetFacultiesQuery, useGetStudentsQuery } from "../../../redux/api/supervisorSlice";

// Importing images
import AllBatchesImage from "../../../Assets/DashBoard/all-batches.svg";
import TotalBoardsResultImage from "../../../Assets/DashBoard/total-board-results.svg";
import TotalCompetitiveExamImage from "../../../Assets/DashBoard/total-competitve-exams.svg";
import TotalFacultiesImage from "../../../Assets/DashBoard/total-faculty.svg";
import TotalStudentsImage from "../../../Assets/DashBoard/total-students.svg";

const HeroBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  gap: "8px",
  flexDirection: "column"
}));

const StatBox = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: "#fff",
  borderRadius: "0.5rem",
  border: "1px solid #E6EDFF",
  padding: "0.75rem",
  display: "flex",
  justifyContent: "start"
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: "1rem",
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "repeat(2, 1fr)"
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "repeat(3, 1fr)"
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(5, 1fr)"
  }
}));

const TotalStats = () => {
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const { data: students, isSuccess: isGetStudentSuccess } = useGetStudentsQuery(user.school_id);
  const { data: faculties, isSuccess: isGetFacultiesSuccess } = useGetFacultiesQuery(user.school_id);
  const { data: batches, isSuccess: isGetBatchesSuccess } = useGetBatchesByStudentQuery({
    schoolId: user.school_id,
    studentId: user._id
  });
  const { data: studentStats, isSuccess: isGetStudentStatsSuccess } = useGetSchoolStudentStatsQuery({
    student_id: user._id,
    school_id: user.school_id
  });

  const statsData = [
    {
      icon: <img src={TotalFacultiesImage} alt="total-Faculties" />,
      value: isGetStudentStatsSuccess && studentStats?.totalFaculty,
      difference: isGetBatchesSuccess && studentStats?.difference?.faculty,
      label: "Total Faculty"
    },
    {
      icon: <img src={TotalStudentsImage} alt="total-Students" />,
      value: isGetStudentSuccess && students.students?.length,
      difference: isGetBatchesSuccess && studentStats?.difference?.students,
      label: "Total Students"
    },
    {
      icon: <img src={TotalCompetitiveExamImage} alt="total-Competitive" />,
      value: isGetBatchesSuccess && studentStats?.totalExamsGiven,
      difference: isGetBatchesSuccess && studentStats?.difference?.examsGiven,
      label: "Exams Created"
    },
    {
      icon: <img src={AllBatchesImage} alt="All-Batches" />,
      value: isGetBatchesSuccess && studentStats?.totalBatches,
      difference: null,
      label: "Total Batches"
    },
    {
      icon: <img src={TotalBoardsResultImage} alt="total-Boards" />,
      value: isGetBatchesSuccess && batches?.batches?.length,
      difference: isGetBatchesSuccess && studentStats?.totalBoardResults,
      label: "Board Results"
    }
  ];

  return (
    <GridContainer>
      {statsData.map((stat, index) => (
        <StatBox key={index}>
          <HeroBox>
            {stat.icon}
            <Typography variant="subtitle2" color={theme.palette.primary.light}>
              {stat.label}
            </Typography>
            <Box display="flex" gap="0.5rem" alignItems="baseline">
              <Typography variant="h2" fontWeight={500} fontSize="32px">
                {stat.value}
              </Typography>
              {stat.difference !== null && stat.difference !== 0 && (
                <Typography variant="subtitle1" fontWeight={500} color="#3A974C">
                  {stat.difference > 0 ? `+${stat.difference}` : stat.difference}
                </Typography>
              )}
            </Box>
          </HeroBox>
        </StatBox>
      ))}
    </GridContainer>
  );
};

export default TotalStats;
