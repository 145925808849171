import { Box, Chip, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import { CaretDown, CursorClick } from "@phosphor-icons/react";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllStudentAnalysisQuery } from "../../../redux/api/analysisSlice";
import BreadcrumbsComp from "../../../utils/Breadcrumbs";

const CTypography = styled(Typography)(({ theme }) => ({
  width: "100%",
  textAlign: "center",
  display: "flex",
  gap: "3px",
  alignItems: "center",
  justifyContent: "center"
}));

const TestHistoryStudent = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");
  const theme = useTheme();

  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);
  const { data: analysisArray, isSuccess: isGetAnalysisSuccess } = useGetAllStudentAnalysisQuery(user._id);

  useEffect(() => {
    document.title = "Insights | Test History";
  }, []);

  const breadcrumbItems = [
    { name: "Dashboard", link: "/" },
    { name: "Test History", link: "#" }
  ];

  return (
    <Box
      padding="1rem 2rem"
      display="flex"
      flexDirection="column"
      gap="0.75rem"
      bgcolor="rgb(250,250,251,1)"
      width="100%"
      height="100%"
    >
      <div style={{ padding: "0 0 0rem 0" }}>
        <BreadcrumbsComp items={{ arr: breadcrumbItems }} />
      </div>
      <Box width="100%" display="flex" flexDirection="column" gap="2rem">
        <Box>
          <Typography variant="h2" fontWeight={600}>
            Test History
          </Typography>
          <Typography variant="h6" color={theme.palette.primary.light}>
            View your test history here
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            overflowX: "auto",
            maxWidth: "100%"
          }}
        >
          <table style={{ width: "100%", textAlign: "center", borderCollapse: "separate", borderSpacing: "0 1rem" }}>
            <thead>
              <tr>
                <th
                  style={{
                    width: "20%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam Name
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Exam Type
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "10%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Date
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "20%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Your Score
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "20%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      Class Average
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
                <th
                  style={{
                    width: "20%",
                    padding: isNonMobile ? "0.5rem" : "1rem",
                    gap: "8px"
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Typography variant="subtitle2" fontWeight={"bold"}>
                      View
                    </Typography>
                    <CaretDown weight="fill" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {analysisArray?.analysisArray ? (
                analysisArray?.analysisArray?.length ? (
                  analysisArray?.analysisArray?.map((analysis, index) => (
                    <tr
                      key={analysis.unique_code}
                      style={{
                        backgroundColor: "#fff",
                        borderRadius: "1rem",
                        borderSpacing: "1rem",
                        cursor: "pointer",
                        transition: "box-shadow 0.3s ease"
                      }}
                      onClick={() => navigate(`/analyse-exam/${analysis._id}`)}
                      onMouseEnter={e => {
                        e.currentTarget.style.boxShadow = "0 0 15px rgba(0, 0, 0, 0.2)";
                      }}
                      onMouseLeave={e => {
                        e.currentTarget.style.boxShadow = "0 0 0 rgba(0, 0, 0, 0)";
                      }}
                    >
                      <td
                        style={{
                          width: "20%",
                          padding: isNonMobile ? "0.5rem" : "1.25rem",
                          gap: "8px",
                          borderLeft: "5px solid #F48C06",
                          borderRadius: "0.5rem"
                        }}
                      >
                        {String(analysis.exam_name).toUpperCase()}
                      </td>
                      <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.exam_for}
                      </td>
                      <td style={{ width: "10%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.exam_date}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {analysis.student_score}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        {Math.floor(analysis.average_score)}
                      </td>
                      <td style={{ width: "20%", padding: isNonMobile ? "0.5rem" : "1.25rem", gap: "8px" }}>
                        <Chip
                          variant="small"
                          sx={{
                            "bgcolor": "rgba(0,0,0,0.05)",
                            "color": "#F48C06",
                            "&:hover": { bgcolor: "rgba(0,0,0,0.09)", cursor: "pointer" },
                            "borderRadius": "3rem",
                            "cursor": "pointer",
                            "fontSize": "14px",
                            "fontWeight": isNonMobile ? undefined : 500,
                            "height": isNonMobile ? undefined : "3rem",
                            "width": isNonMobile ? undefined : "100%"
                          }}
                          label="View Analysis"
                          icon={<CursorClick size={20} />}
                          component={Link}
                          to={`/analyse-exam/${analysis._id}`}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center", padding: "1rem" }}>
                      <Typography variant="subtitle1" fontWeight="500">
                        No tests available.
                      </Typography>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan="6" style={{ textAlign: "center", padding: "1rem" }}>
                    Loading...
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Box>
      </Box>
    </Box>
  );
};

export default TestHistoryStudent;
