// inbuilt modules
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

// external modules
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

// internal modules
import InsightsDashaBoardAnalysisForFaculty from "../../../components/Teacher/Dashboard/InsightsAnalysis";
import OverallAnalysisTeacher from "../../../components/Teacher/Dashboard/OverallAnalysis";
import TotalStats from "../../../components/Teacher/Dashboard/Totalstats";
import TestHistoryTeacher from "../../../components/Teacher/TestHistory/test";

import BoardResultsBanner from "../../../components/Teacher/Dashboard/BoardResultsBanner";
import TeacherBanner from "../../../components/Teacher/Dashboard/TeacherBanner";
import DashboardDisplay from "../../../components/Teacher/ManageBatch/DashboardDisplay";

import { useGetAllFacultyAnalysisQuery } from "../../../redux/api/analysisSlice";
import { useGetBatchesByFacultyQuery } from "../../../redux/api/batchSlice";

const TeacherDashboard = () => {
  const isNonMobile = !useMediaQuery("(max-width:600px) or (max-height:600px) or (orientation: portrait)");

  // hooks
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.auth);
  const [batchNames, setbatchNames] = useState([]);
  const { data: analysisData, isSuccess: isAnalysisSuccess } = useGetAllFacultyAnalysisQuery({
    school_id: user?.school_id,
    faculty_id: user._id
  });

  const { data: batchesOfFaculty, isSuccess: isBatchSuccess } = useGetBatchesByFacultyQuery({
    faculty_id: user?._id,
    school_id: user?.school_id
  });

  useEffect(() => {
    if (isBatchSuccess && batchesOfFaculty?.batches?.length > 0) {
      const totalBatches = batchesOfFaculty.batches.map(batch => batch?.batch_name);
      setbatchNames(totalBatches);
    }
  }, [batchesOfFaculty, isBatchSuccess]);

  const [totalExams, setTotalExams] = useState([]);

  useEffect(() => {
    let uniqueExams = new Set();
    if (analysisData && analysisData.analysisArray.length > 0) {
      analysisData.analysisArray.forEach(exam => {
        if (exam.exam_for) {
          if (exam.exam_for !== "undefined") uniqueExams.add(exam.exam_for);
        }
      });
      setTotalExams(Array.from(uniqueExams));
    }
  }, [analysisData]);

  // redux
  // render
  return (
    <main>
      <Helmet>
        <title>Insights | Dashboard</title>
        <meta name="description" content="Start your detailed Analysis with Insights." />
        <meta name="robots" content="noindex" />
        <link rel="canonical" href={window.location.href + window.location.search} />
        <meta property="og:title" content="Insights | Dashboard" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content="Start your detailed Analysis with Insights." />
        <meta property="og:image" content="http://euro-travel-example.com/thumbnail.jpg" />
        <meta property="og:url" content="http://localhost:3000/" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Box
        padding={isNonMobile ? "1rem 2rem" : "1rem 1.25rem"}
        display="flex"
        flexDirection="column"
        gap="0.75rem"
        bgcolor="rgb(250,250,251,1)"
        width="100%"
        height="100%"
      >
        <Box display="flex" gap="0.75rem" flexDirection="column">
          <Typography variant={isNonMobile ? "h2" : "h3"} fontWeight={600}>
            Welcome Back, {user.name}
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.primary.light}>
            Here you can get all the information of your institute
          </Typography>
        </Box>
        <TotalStats />
        <TeacherBanner />
        {isAnalysisSuccess && totalExams.length > 0 && batchNames.length > 0 && user && (
          <OverallAnalysisTeacher
            userData={user}
            analysisData={analysisData}
            totalExams={totalExams}
            batchNames={batchNames}
          />
        )}
        {user && <InsightsDashaBoardAnalysisForFaculty userData={user} />}
        {/* <LineGraph /> */}
        <Box display={"flex"} gap={"2rem"}>
          {/* <TopPerformersTeacher /> */}
          <TestHistoryTeacher />
        </Box>
        <DashboardDisplay />
        <BoardResultsBanner />
      </Box>
    </main>
  );
};

// render
export default TeacherDashboard;
