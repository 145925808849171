import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import toast from "react-hot-toast";

// Helper function to serialize dates in the request body
const serializeDates = body => {
  const serializedBody = { ...body };
  if (body.startDate instanceof Date) {
    serializedBody.startDate = body.startDate.toISOString();
  }
  if (body.endDate instanceof Date) {
    serializedBody.endDate = body.endDate.toISOString();
  }
  return serializedBody;
};

export const performanceApiSlice = createApi({
  reducerPath: "performanceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_BASE_URL}/performance`,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),
  tagTypes: ["Performance"],
  endpoints: build => ({
    getStudentPerformanceInRange: build.query({
      query: params => ({
        url: "/getStudentPerformanceInRange",
        method: "POST",
        body: serializeDates(params)
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          toast.loading("Creating Issue", { id: "createIssue" });
          await queryFulfilled;
          toast.success("Issue Created Successfully", { id: "createIssue" });
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    }),
    getLatestExamPerformance: build.query({
      query: params => ({
        url: "/getLatestExamPerformance",
        method: "POST",
        body: params
      }),
      providesTags: ["Performance"]
    }),
    getSchoolStudentStats: build.query({
      query: params => ({
        url: "/getSchoolStudentStats",
        method: "POST",
        body: params
      }),
      providesTags: ["Performance"]
    }),
    getStudentPerformanceInRangeByBatch: build.query({
      query: params => ({
        url: "/getStudentPerformanceInRangeByBatch",
        method: "POST",
        body: serializeDates(params)
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    }),
    getStudentPerformanceInRangeByBatchForFaculty: build.query({
      query: params => ({
        url: "/getStudentPerformanceInRangeByBatchForFaculty",
        method: "POST",
        body: serializeDates(params)
      }),
      providesTags: ["Performance"],
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const message = error.data?.message || "Server not reachable. Please contact admins";
          toast.error(message, { id: "createIssue" });
        }
      }
    })
  })
});

export const {
  useGetLatestExamPerformanceQuery,
  useGetSchoolStudentStatsQuery,
  useGetStudentPerformanceInRangeQuery,
  useGetStudentPerformanceInRangeByBatchQuery,
  useGetStudentPerformanceInRangeByBatchForFacultyQuery
} = performanceApiSlice;
